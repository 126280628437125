import React, {useEffect, useState, useCallback, useRef, useMemo} from "react";
import AutoBetsByLoseOrWins from "./AutoBetsByLoseOrWins";
import {StyledButton} from "../../styles/styledButton";
import Dialog from "rc-dialog";
import {useTranslation} from "react-i18next";
import {useBetween} from "use-between";
import {
    useAutoBetsStatesDice,
    useAutoBetsStatesBalls,
    useAutoBetsStatesRoulette,
} from "./AutoBetsStates";
import {StyledFlexInline} from "./styledAutoBets";
import AlertMessage from "../alert/Alert";
import AutoBetsLimits from "./AutoBetsLimits";
import AutoBetsAcceleration from './AutoBetsAcceleration';
import {GAMES} from '../../games/Constants';
import BalanceStates from '../../games/BalanceStates';

const AutoBetsContainer = ({
                               game: currentGame,
                               toPlay,
                               responseData,
                               submitData,
                               setSubmitData,
                               setStopAnimation = null,
                               errorData,
                               setErrorData,
                               isMobile,
                               isPlay,
                               animationDisabled,
                               resetBet,
                           }) => {
    const [autoBetsModalVisible, setAutoModalBetsVisible] = useState(false);
    const [wakeLock, setWakeLock] = useState(null);
    const {t} = useTranslation("games");
    const {balance} = useBetween(BalanceStates);

    const diceAutoBetsData = useAutoBetsStatesDice();
    const ballsAutoBetsData = useAutoBetsStatesBalls();
    const rouletteAutoBetsData = useAutoBetsStatesRoulette();

    const {
        autoModeIsStart,
        setAutoModeIsStart,
        setAutoMode,
        autoMode,
        setGame,
        game,
        startAutoBets,
        gameInfoRef,
        isDisableAutoBet,
        setIsDisableAutoBet,
        isStartRequestStopAutoBets,
        setIsStartRequestStopAutoBets,
    } = useMemo(
        () => {
            const data = {
                [GAMES.DICE]: diceAutoBetsData,
                [GAMES.BALLS]: ballsAutoBetsData,
                [GAMES.ROULETTE]: rouletteAutoBetsData,
            }
            return data[currentGame];
        },
        [currentGame, diceAutoBetsData, ballsAutoBetsData, rouletteAutoBetsData],
    );

    const [values, setValues] = useState({...autoMode});
    const isAutoStartRef = useRef(false);
    const isButtonPressRef = useRef(false);
    const autoButtonRef = useRef(null);

    const updateGameInfo = useCallback((key, value) => {
        if (gameInfoRef.current) {
            gameInfoRef.current[key] = value;
        }
    }, [gameInfoRef]);

    useEffect(() => {
        updateGameInfo('animationDisabled', animationDisabled);
        updateGameInfo('balance', balance);
        updateGameInfo('resetBet', resetBet);
        updateGameInfo('responseData', responseData);
        updateGameInfo('submitData', submitData);
        updateGameInfo('errorData', errorData);
        updateGameInfo('toPlay', toPlay);
        updateGameInfo('autoModeIsStart', autoModeIsStart);
        updateGameInfo('setSubmitData', setSubmitData);
        updateGameInfo('setErrorData', setErrorData);
        updateGameInfo('setStopAnimation', setStopAnimation);
    }, [updateGameInfo, animationDisabled, balance, resetBet, responseData, submitData, errorData, toPlay, autoModeIsStart, setSubmitData, setErrorData, setStopAnimation]);

    useEffect(() => {
        setGame(currentGame);
    }, [currentGame, setGame]);

    const formatAutoBet = useCallback((values) => {
        const newValues = {...values};
        const numericFields = ['lose.countOfSubsequence', 'wins.countOfSubsequence', 'lose.increase.value', 'wins.increase.value', 'lose.decrease.value', 'wins.decrease.value', 'maxCountOfBet', 'stopByMinBalanceAmount', 'stopByMaxBalanceAmount', 'stopByMaxBetsAmount'];

        numericFields.forEach(field => {
            const [category, subCategory, key] = field.split('.');
            if (subCategory && key) {
                if (newValues[category][subCategory][key] !== '') {
                    newValues[category][subCategory][key] = Number(newValues[category][subCategory][key]) || '';
                }
            } else if (subCategory) {
                newValues[category][subCategory] = Math.max(Number(newValues[category][subCategory]) || 1, 1);
            } else {
                if (newValues[category] !== '') {
                    newValues[category] = Number(newValues[category]) || '';
                }
            }
        });

        return newValues;
    }, []);

    const getGameInfo = useCallback(() => gameInfoRef.current, [gameInfoRef]);

    const handleStartClick = useCallback(() => {
        if (isButtonPressRef.current) return true;

        isButtonPressRef.current = true;
        isAutoStartRef.current = true;
        setAutoModalBetsVisible(false);

        setAutoMode({
            ...formatAutoBet(values),
            firstBet: submitData.bet,
            firstSign: submitData.sign,
            firstSuggestedNumbers: submitData.suggestedNumbers,
        });

        setTimeout(() => {
            isButtonPressRef.current = false;
        }, 1000);

        return true;
    }, [setAutoMode, submitData.bet, submitData.sign, submitData.suggestedNumbers, formatAutoBet, values]);

    useEffect(() => {
        if (isAutoStartRef.current) {
            isAutoStartRef.current = false;
            startAutoBets();
        }
    }, [startAutoBets]);

    useEffect(() => {
        if (getGameInfo().pausedGame && getGameInfo().pausedGame[game]) {
            getGameInfo().pausedGame[game] = null;
        }
    }, [getGameInfo, game]);

    useEffect(() => {
        const checkAnimationCount = () => {
            setIsDisableAutoBet(gameInfoRef.current.animationCount > 0);
        };

        const intervalId = setInterval(checkAnimationCount, 400);
        return () => clearInterval(intervalId);
    }, [setIsDisableAutoBet, gameInfoRef]);

    const waitForPlayState = useCallback((desiredState) => {
        return new Promise((resolve) => {
            const check = () => {
                if (getGameInfo().isPlay === desiredState || !getGameInfo().autoModeIsStart) {
                    resolve();
                } else {
                    setTimeout(check, 5);
                }
            };
            check();
        });
    }, [getGameInfo]);

    const handleClickAuto = useCallback(async () => {
        if (autoModeIsStart) {
            if (autoButtonRef.current) {
                autoButtonRef.current.disabled = true;
            }
            setIsStartRequestStopAutoBets(true);

            await waitForPlayState(false);

            const isAnimation = currentGame === GAMES.ROULETTE ? (autoMode.acceleration === 0) :
                (autoMode.acceleration < 3 && !animationDisabled);

            if (!isAnimation) {
                await waitForPlayState(true);
            }

            setAutoModeIsStart(false);
            setIsStartRequestStopAutoBets(false);
            setTimeout(() => {
                if (autoButtonRef.current) {
                    autoButtonRef.current.disabled = false;
                }
            }, 1000);
        } else if (getGameInfo().animationCount === 0) {
            setAutoModalBetsVisible(true);
        }
    }, [autoModeIsStart, setAutoModeIsStart, setIsStartRequestStopAutoBets, getGameInfo, waitForPlayState, currentGame, autoMode.acceleration, animationDisabled]);

    const isButtonDisabled = (!autoModeIsStart && isPlay) || (!autoModeIsStart && isDisableAutoBet) || isStartRequestStopAutoBets;

    const acquireWakeLock = async () => {
        try {
            if ('wakeLock' in navigator && wakeLock === null) {
                const newWakeLock = await navigator.wakeLock.request('screen');
                setWakeLock(newWakeLock);
            }
        } catch (err) {
            console.error(`Error acquiring wake lock: ${err}`);
        }
    };

    const releaseWakeLock = async () => {
        if (wakeLock !== null) {
            await wakeLock.release();
            setWakeLock(null);
        }
    };

    useEffect(() => {
        if (autoModeIsStart) {
            acquireWakeLock();
        } else {
            releaseWakeLock();
        }
        return () => {
            releaseWakeLock();
        };
    }, [autoModeIsStart]);

    return (
        <>
            <StyledButton
                ref={autoButtonRef}
                color="neutral"
                onClick={handleClickAuto}
                isMobile={isMobile}
                isAutoBet={true}
                disabled={isButtonDisabled}
                style={(isMobile && currentGame === GAMES.ROULETTE) ? {
                    marginTop: '0px',
                    marginBottom: '10px',
                } : {}}
            >
                {(!autoModeIsStart || isStartRequestStopAutoBets) ? t("auto") : t("stop")}
            </StyledButton>
            <Dialog
                visible={autoBetsModalVisible}
                wrapClassName={`default-modal-wrapper ${game} auto-bets-modal`}
                onClose={() => setAutoModalBetsVisible(false)}
                animation="zoom"
                maskAnimation="fade"
                title={t("autoBets")}
                destroyOnClose={true}
                footer={<StyledButton
                    color="neutral"
                    width="100"
                    onClick={handleStartClick}
                    disabled={autoModeIsStart || isPlay}
                >
                    {t('start')}
                </StyledButton>}
                forceRender={false}
                className="default-modal-wider"
            >
                <StyledFlexInline>
                    <AutoBetsByLoseOrWins
                        game={currentGame}
                        autoMode={values}
                        setAutoMode={setValues}
                        direction="lose"
                    />
                    <AutoBetsByLoseOrWins
                        game={currentGame}
                        autoMode={values}
                        setAutoMode={setValues}
                        direction="wins"
                    />
                </StyledFlexInline>
                <AutoBetsAcceleration
                    autoMode={values}
                    setAutoMode={setValues}
                />
                <AutoBetsLimits
                    autoMode={values}
                    setAutoMode={setValues}
                />
                <AlertMessage
                    type="warning"
                    message={t("automatedBettingHelps") + " "}
                    messageBold={t("pleaseUseIt")}
                    mt="20"
                />
            </Dialog>
        </>
    );
};

export default AutoBetsContainer;