import React from "react";
import PageSpinner from "../elements/spinner/PageSpinner";
import { StyledBlockTitle } from "../styles/styledDocumetnElemets";
import { StyledContentWrapper } from "../styles/styledContainer";
import { StyledProvablyFair } from "./styledDetails";
import InputGroup from "../elements/inputGroup/InputGroup";
import { useTranslation } from "react-i18next";
import { StyledLabel } from "../elements/inputGroup/styledInputGroup";

const CheckHonestyControl = ({ bet }) => {
  const { t } = useTranslation("leftSidebar");
  const { t: tO } = useTranslation("siteOptions");

  if (!bet) {
    return <PageSpinner />
  }

  if (!bet.honestyControl) {
    return "";
  }

  return (
    <StyledContentWrapper width="780">
      <StyledBlockTitle pb="15">
          {tO("fair")}
      </StyledBlockTitle>
      <StyledProvablyFair>
        <InputGroup
          id="clientSeed"
          label={t("userLine")}
          type="text"
          className="text"
          value={bet.honestyControl.clientSeed}
          padding="7px 20px"
          readOnly
        />
        <InputGroup
          id="clientSeed"
          label={t("serverLine")}
          type="text"
          className="text"
          value={bet.honestyControl.serverSeed}
          padding="7px 20px"
          readOnly
        />
        <InputGroup
          id="clientSeed"
          label={t("serverLine") + " (HASH)"}
          type="text"
          className="text"
          value={bet.honestyControl.serverSeedHash}
          padding="7px 20px"
          readOnly
        />
        <InputGroup
          id="clientSeed"
          label={t("resultNumber")}
          type="text"
          className="text"
          value={bet.resultNumber}
          padding="7px 20px"
          readOnly
        />
        <StyledLabel>{t("verification")} (PHPTester):</StyledLabel>
        <div className="code-field">
          <p>
            &lt;?PHP
            <br />
            <br />
                $client_seed = '{bet.honestyControl.clientSeed}';
            <br />
                $server_seed = '{bet.honestyControl.serverSeed}';
            <br />
            <br />
                $server_hash = hash_hmac('sha512', $server_seed , $client_seed );
            <br />
                $result_number = hexdec(substr( $server_hash, 0, 10));
            <br />
                $lucky_number = abs( $result_number % '{bet.gameObject.max + 1}' );
            <br />
            <br />
                echo $lucky_number;
            <br />
          </p>
        </div>
      </StyledProvablyFair>
    </StyledContentWrapper>
  );
};

export default CheckHonestyControl;