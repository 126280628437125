import styled from "styled-components";

const gamesBg = (game, theme) => {
  switch (game) {
    case "dice":
      return `
        background-image: url(${theme.leftSidebarGames.dice});
      `;
    case "roulette":
      return `
        background-image: url(${theme.leftSidebarGames.roulette});
      `;
    case "balls":
      return `
        background-image: url(${theme.leftSidebarGames.balls});
      `;
  }
};

export const StyledHeaderLeftSidebar = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    color: ${({ theme }) => theme.subTitleColor};
    font-size: 24px;
    font-weight: 400;
  }

  .cancel {
    cursor: pointer;
    transition: all 0.3s ease-out;
    color: #9f9f9f;
    font-size: 27px;
    &:hover {
      color: #ffffff;
    }
  }

  @media screen and (max-width: 1100px) {
    display: flex !important;
  }
`;

export const StyledLeftSidebarGamesWrapper = styled.div`
  padding: 20px;
`;

export const StyledFlexWrapper = styled.section`
  display: flex;
  justify-content: start;
  align-items: baseline;
  flex-wrap: wrap;
  @media screen and (max-width: 1100px) {
    justify-content: space-around;
  }
`;

export const StyledGameItem = styled.div`
  width: 310px;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 5px;
  display: block;
  position: relative;
  overflow: hidden;
  opacity: 0.8;
  transition: all 0.15s ease-in-out;
  ${({ game, theme }) => gamesBg(game, theme)};
  background-size: cover;
  background-repeat: no-repeat;

  span {
    padding: 7px 9px;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0;
    right: 0;
    color: #ffffff;
  }

  &:hover {
    opacity: 1;
  }

  @media screen and (max-width: 1000px) {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  @media screen and (max-width: 690px) {
    &:not(:last-child) {
      margin-right: 0;
    }
  }
`;

export const StyledGamesItemTitle = styled.div`
  width: 200px;
  font-size: 37px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing}px`};
  background-color: ${({ theme }) => theme.leftSidebarGames.titleColor};
  background-image: ${({ theme }) => theme.leftSidebarGames.titleGradientColor};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  ${({ top }) => top && `top: ${top}px`};
  ${({ left }) => left && `left: ${left}px`};
`;