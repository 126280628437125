import styled, { css } from "styled-components";
import { device, hideOnMedia } from "../../styles/responsive/responsiveUtils";
import { StyledMessageAvatar } from "../leftSidebar/Chat/ChatMessages/styledChatMessages";

export const StyledMobileNavWrapper = styled.div`
  display: none;
  @media screen and ${device('desktop')} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 60px);
  }
`;

export const StyledBurger = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 22px;
  height: 17px;
  margin-left: 11px;
  cursor: pointer;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  span {
    display: block;
    height: 3px;
    width: 100%;
    border-radius: 3px;
    background: ${({ theme }) => theme.grayForHeader};
    text-align: center;
    transition: transform 0.2s ease-in-out;

    &:first-child {
      transform-origin: 0 0;
      transition: transform 0.4s ease-in-out;
    }

    &:last-child {
      transform-origin: 0 100%;
      transition: transform 0.4s ease-in-out;
    }
  }

  .active-burger {
    transform: scaleY(0);

    &:first-child {
      transform: rotate(43deg);
    }

    &:last-child {
      transform: rotate(-43deg);
    }
  }
`;

export const StyledChat = styled.div`
  margin: 0 0 0 11px;
  height: 21px;

  img {
    opacity: .6;
    height: 100%;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
`;

export const StyledGames = styled.div`
  margin: 0 0 0 11px;
  height: 24px;

  img {
    opacity: .6;
    height: 100%;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  ${hideOnMedia(440)}
`;

export const StyledFair = styled.div`
  margin-left: 8px;
  height: 20px;

  img {
    opacity: .6;
    height: 100%;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  @media screen and ${device('xs', 'min')} {
    margin-left: 11px;
  }

  ${hideOnMedia(510)}
`;

export const StyledStats = styled.div`
  margin: 0 0 0 11px;
  height: 20px;

  img {
    opacity: .6;
    height: 100%;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  ${hideOnMedia(550)}
`;

export const StyledToken = styled.div`
  margin: 0 0 0 11px;
  height: 30px;

  img {
    opacity: .6;
    height: 100%;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  ${hideOnMedia(580)}
`;

export const StyledBonus = styled.div`
  margin: 0 0 0 11px;
  height: 20px;

  img {
    opacity: .6;
    height: 100%;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  ${hideOnMedia(560)}
`;

export const StyledMoney = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: #171717;
  cursor: pointer;
  opacity: ${({ isDisabled }) => isDisabled ? 0.6 : 1};

  span {
    display: block;
    width: 20px;
    height: 20px;
  }
`;

export const StyledBalance = styled.div`
  background: #171717;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  border-radius: 4px;
  margin-left: 11px;
  padding: 8px 12px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  opacity: ${({ isDisabled }) => isDisabled ? 0.6 : 1};

  div {
    margin-bottom: 0;
    height: 32px;

    input:read-only {
      padding: 0;
      height: 32px;
      border: unset;
      text-align: center;
      background-color: #171717;
      color: #fff;
      font-size: 14px;
    }
  }

  &:hover {
    background: ${({ isDisabled }) => isDisabled ? '#171717' : '#252525'};
  }
`;

export const StyledAnimatedProfit = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  color: ${({ win }) => win ? "#2eac5b" : "#ab2e40"};
  animation: 500ms linear alternate sun-rise-rev;

  @keyframes sun-rise-rev {
    from {
      transform: translate(-50%, 0px);
      opacity: 1;
      visibility: visible;
    }
    to {
      transform: translate(-50%, 18px);
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export const StyledNavAvatar = styled(StyledMessageAvatar)`
  margin-left: 11px;
  ${hideOnMedia('xs')}
`;

export const StyledEnter = styled(StyledBalance)`
  height: 32px;
  padding: 9px 15px;
  margin: 0 5px;
  text-transform: uppercase;
  cursor: pointer;

  @media screen and (max-width: 540px) {
    font-size: 12px;
    padding: 9px 7px;
  }

  @media screen and (max-width: 375px) {
    margin: 0 3px;
  }
`;

export const StyledBalanceNavContent = styled.div`
  display: flex !important;
  padding: 20px;
  flex-direction: column;
  gap: 8px;
  
  & > button {
    background-color: ${({theme}) => theme.mobileNav.item.backgroundColor};
    color: ${({theme}) => theme.mobileNav.item.color};
    border: none;
    border-radius: 2px;
    cursor: pointer;
    font-size: 16px;
    padding: 8px 15px;
    text-align: start;
    
    &:hover {
      background-color: ${({theme}) => theme.mobileNav.itemHover.backgroundColor};
      color: ${({theme}) => theme.mobileNav.itemHover.color};
    }
  }
  
`;