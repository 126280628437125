import styled from "styled-components";

export const CustomSelectStyle = styled.div`
    position: relative;
    user-select: none;
    padding: 5px 8px 7px;
    margin: 2px 0 5px 0;
    font-size: 16px;
    vertical-align: middle;
    background-color: ${({theme}) => theme.inputBgColor};
    border: 1px ${({theme}) => theme.inputBorderColor} solid;
    color: ${({theme}) => theme.inputColor};
    border-radius: 3px;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
    box-sizing: content-box;

    .custom-select-header {
        display: block;
        width: 80px;
        line-height: 21.5px;
    }
    
    &.focus {
        border-bottom: 1px solid #058bd8;
    }
    
    &::after {
        border-bottom: 2px solid ${({theme}) => theme.customSelect.arrow};
        border-right: 2px solid ${({theme}) => theme.customSelect.arrow};
        content: '';
        display: block;
        height: 5px;
        margin-top: -4px;
        pointer-events: none;
        position: absolute;
        right: 8px;
        top: 50%;
        -webkit-transform-origin: 66% 66%;
        -ms-transform-origin: 66% 66%;
        transform-origin: 66% 66%;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transition: all 0.15s ease-in-out;
        transition: all 0.15s ease-in-out;
        width: 5px;
    }
    
    &.focus::after {
        transform: rotate(-135deg);
    }

    .custom-select-list {
        left: -1px;
        top: 100%;
        margin-top: 5px;
        cursor: pointer;
        border: 1px solid ${({theme}) => theme.customSelect.blockBorderColor};
        color: ${({theme}) => theme.inputColor};
        font-size: 16px;
        font-weight: bold;
        z-index: 10;
        border-radius: 3px;
        overflow: hidden;
        position: absolute;
        width: calc(100% + 2px);
        background-color: ${({theme}) => theme.customSelect.bgList};
    }

    .custom-select-list-option {
        padding: 5px 8px 7px;
        cursor: pointer;
        line-height: 16px;
        z-index: 12;
        cursor: pointer;
        &:hover {
            background-color: ${({theme}) => theme.customSelect.blockHover};
        }
        &.selected {
            background-color: ${({theme}) => theme.customSelect.blockHover};
        }
        &.selected:hover {
            background-color: ${({theme}) => theme.customSelect.blockHover} !important;
        }
    }
    
    .custom-select-list:hover .selected {
        background-color: ${({theme}) => theme.customSelect.bgList};
    }
`