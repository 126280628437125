import styled from "styled-components";

export const StyledTitle = styled.h2`
  margin: 35px 0 25px 0;
  color: ${({theme}) => theme.defaultChangeColor};
  font-size: 26px;
  line-height: 26px;
`;

export const StyledTitleBlock = styled(StyledTitle)`
  margin: 70px 0 40px;
  color: ${({theme}) => theme.text};
`;

export const StyledBlockContent = styled.div`
  background: ${({theme}) => theme.readingPage.borderColor};
  border-radius: 5px;
  padding: 15px;
  text-align: center;
  
  &.transaction-page {
      background: ${({theme}) => theme.transactionHist.nothingBg};
      color: ${({theme}) => theme.transactionHist.nothingTitle};
      font-size: 16px;
      line-height: 21.5px;
  }  

  @media screen and (max-width: 840px) {
    img {
      width: 500px;
    }
  }
  @media screen and (max-width: 600px) {
    img {
      width: 400px;
    }
  }
  @media screen and (max-width: 480px) {
    img {
      width: 300px;
    }
  }
  @media screen and (max-width: 375px) {
    img {
      width: 200px;
    }
  }
`;

export const StyledCode = styled.code`
  display: block;
  border: 1px #333333 dashed;
  padding: 5px;
  margin-top: 10px;
  font-size: 15px;
  word-break: break-word;
  cursor: pointer;
`;

export const StyledSmall = styled.small`
  color: ${({theme}) => theme.text};
  font-size: 16px;
  margin-left: 5px;
  
  a {
    margin-left: 5px;
    border-bottom: 1px #9f9f9f dashed;
  }

  .icon-user {
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-right: 10px;
  }
`;