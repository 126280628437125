export const formatNumber = (num, precision = 8) => {
    if (!num) return "";

    const str = num.toString();
    const parts = str.split('.');
    if (parts.length === 1) {
        return `${str}.${'0'.repeat(precision)}`;
    }
    if (parts[1].length < precision) {
        return `${parts[0]}.${parts[1].padEnd(precision, '0')}`;
    }
    return `${parts[0]}.${parts[1].slice(0, precision)}`;
};