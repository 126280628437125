import styled from "styled-components";
import Dialog from "rc-dialog";
import bonusModalBg from "../../../../assets/images/leftSidebar/bonus-rules/background-modal.svg";
import bgBlockGreen from "../../../../assets/images/leftSidebar/bonus-rules/bonus-info-green.svg";
import bgBlockYellow from "../../../../assets/images/leftSidebar/bonus-rules/bonus-info-yellow.svg";
import bgBlockBlue from "../../../../assets/images/leftSidebar/bonus-rules/bonus-info-blue.svg";
import bgBlockRed from "../../../../assets/images/leftSidebar/bonus-rules/bonus-info-red.svg";

export const StyledBonusRulesDialog = styled(Dialog)`
    &.default-modal-bonus {
        border-radius: 10px;
        overflow: hidden;
        margin: auto;

        & > .rc-dialog-content::before {
            content: "";
            display: block;
            position: absolute;
            top: -2px;
            left: 0;
            right: 6px;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: url(${bonusModalBg}) no-repeat center top;
            background-size: 190%, auto;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 6px;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        & .rc-dialog-content {
            overflow: hidden;

            .rc-dialog-body {
                background: none;
            }

            .rc-dialog-header {
                border-bottom: none;
                z-index: 2;
                background: none;
                box-shadow: none;

                .rc-dialog-title {
                    color: ${({theme}) => theme.bonusGame.titleForm};
                }
            }

            .rc-dialog-close {
                border: 1px solid${({theme}) => theme.bonusGame.titleForm};
                color: ${({theme}) => theme.bonusGame.titleForm};
            }
        }
    }

    .custom-hint {
        position: relative;
        width: 0;
        transform: translate(20px, -70px);
        z-index: 100;
    }

    .rules-text-block {
        p {
            font-weight: 500;
            padding: 10px 0;
            color: ${({theme}) => theme.bonusGame.textSec};

            a, .link {
                color: ${({theme}) => theme.bonusGame.link};
                cursor: pointer;
            }

            a:hover, .link:hover {
                text-decoration: underline;
                text-underline-offset: 3px;
            }
        }
    }
`

export const StyledBonusRulesBlockList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
`

export const StyledBonusRulesBlockBlock = styled.div`
    width: 48%;
    display: flex;
    flex-direction: column;
    background: ${({theme}) => theme.bonusRules.background};
    z-index: 2;
    border-radius: 9px;
    overflow: hidden;
    margin-bottom: 18px;

    &:nth-child(1) > div:first-child {
        background-image: url("${bgBlockGreen}");
    }

    &:nth-child(2) > div:first-child {
        background-image: url("${bgBlockYellow}");
    }

    &:nth-child(3) > div:first-child {
        background-image: url("${bgBlockBlue}");
    }

    &:nth-child(4) > div:first-child {
        background-image: url("${bgBlockRed}");
    }
`

export const StyledMainBlock = styled.div`
    height: 186px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2;
    background-size: contain;
    background-repeat: no-repeat;
    color:rgba(0,0,0,0.75);
    margin-bottom: 5px;
    position: relative;

    .percent {
        font-weight: 600;
        font-size: 35px;
        line-height: 35px;
        color:rgba(0,0,0,0.75);
        margin-top: -20px;
    }

    .title {
        font-weight: 600;
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 20px;
    }
    
    .deposit-number {
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
    }

    @media screen and (max-width: 520px) {
        .percent {
            font-weight: 600;
            font-size: 28px;
            line-height: 28px;
            color:rgba(0,0,0,0.75);
            margin-top: -16px;
        }

        .title {
            font-weight: 600;
            font-size: 22.4px;
            line-height: 22.4px;
            margin-bottom: 16px;
        }

        .deposit-number {
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
        }
    }

    @media screen and (max-width: 400px) {
        .title {
            margin-bottom: 10px;
        }
        .percent {
            margin-top: -50px;
        }
    }
`
export const StyledLimitBlock = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    padding: 0 10px 3px 10px;
    font-size: 14px;
    
    &:last-child {
        padding-bottom: 10px;
    }
    
    .title-limit {
        color: ${({ theme }) => theme.bonusRules.limitText};
    }
    
    .title-value {
        color: ${({ theme }) => theme.text};
    }
`
