import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Notification from "rc-notification";
import LeftSidebarCurrentStatisticsInfo from "./LeftSidebarCurrentStatisticsInfo";
import LeftSidebarAccountStatisticsContainer from "./LeftSidebarAccountStatisticsContainer";
import { useBetween } from "use-between";


import {
  StyledLeftSidebarStatisticsWrapper,
  StyledStatisticsTop
} from "./styledStatistics";
import BalanceStates from "../../../games/BalanceStates";
// import { MainShareableStatistics } from "./MainShareableStatistics";
import CurrentStatisticsStates from "../../../games/CurrentStatisticStates";
import { StyledHeaderLeftSidebar } from "../Games/styledLeftSidebarGames";
// import { defaultCurrentStatistics } from "../../../../utils/consts";

const LeftSidebarStatisticsContainer = React.memo(({ setVisible, setMenu, user }) => {
  const inctenceId = useRef(`id_${Math.round(Math.random() * 1000)}`);
  const { t } = useTranslation("leftSidebar");

  const { clearCurrentStatistics } = useBetween(CurrentStatisticsStates);

  const { activeCurrency, selectedPaymentMethod } = useBetween(BalanceStates);

  const [notification, setNotification] = useState({
    visible: false,
    type: "",
    message: ""
  });

  // let user = getUserInfo();

  // const currentCurrencyName = JSON.parse(localStorage.getItem("paymentMethod"))?.currency.name;
  const currentCurrencyName = selectedPaymentMethod?.currency.name;


  console.log('RENDER <LeftSidebarStatisticsContainer />', inctenceId);
  return (
    <StyledLeftSidebarStatisticsWrapper>
      <Notification
        notification={notification}
        setNotification={setNotification}
      />
      <StyledHeaderLeftSidebar>
        <h3>
          {currentCurrencyName} {t("statistics")}
        </h3>
        <span
          className="cancel" onClick={() => {
          setVisible(false);
          setMenu("");
        }}
        >✕</span>
      </StyledHeaderLeftSidebar>
      <StyledStatisticsTop className="statistic-flex">
        <p>
          {t("currentSession")}
        </p>
        <button onClick={clearCurrentStatistics}>{t("clear")}</button>
      </StyledStatisticsTop>

      <LeftSidebarCurrentStatisticsInfo
        activeCurrency={activeCurrency}
      />
      <StyledStatisticsTop pt="20">
        <p>
          {t("generalStatistics")}
        </p>
      </StyledStatisticsTop>
      <LeftSidebarAccountStatisticsContainer
        activeCurrency={activeCurrency}
        selectedPaymentMethod={selectedPaymentMethod}
        notification={notification}
        setNotification={setNotification}
        user={user}
      />
    </StyledLeftSidebarStatisticsWrapper>
  );
});

export default LeftSidebarStatisticsContainer;
