import React, { useImperativeHandle, useRef, useEffect } from "react";
// import { animated, easings, useSpring, useSprings, to } from "@react-spring/web";
import {
  StyledRouletteNum,
  StyledRouletteTable,
} from "./styledRoulette";
import Roulette from './Roulette';
import RouletteNums from './RouletteNums';


const RouletteTable = React.forwardRef(({
  submitData,
  setSubmitData,
  // responseData,
  textRates,
  // isRotate,
  // setIsRotate,
  autoModeIsStart,
  autoModeAcceleration,
  isPlay,
  orientation,
  selectedNumber,
  isMobile,
}, ref) => {
  const containerRef = useRef();
  const rouletteRef = useRef();


  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault(); // prevents the default right-click menu from appearing
    }
    if (containerRef.current) {
      containerRef.current.addEventListener('contextmenu', handleContextMenu);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('contextmenu', handleContextMenu);
      }
    }
  }, [containerRef]);

  useImperativeHandle(ref, () => ({
    rotateTo: (
      number,
      isAnimationDisbaled = false,
      isAutoPlay = false,
      onSelectNumber = null,
      autoModeAcceleration = 3,
    ) => rouletteRef.current.rotateTo(number, isAnimationDisbaled, isAutoPlay, onSelectNumber, autoModeAcceleration),
  }), [rouletteRef]);

  return (
    <StyledRouletteTable
      ref={containerRef}
    >
      <Roulette
        ref={rouletteRef}
        isMobile={isMobile}
      />

      <StyledRouletteNum
        isMobile={isMobile}
      >
        <RouletteNums
          submitData={submitData}
          setSubmitData={setSubmitData}
          // responseData,
          textRates={textRates}
          // isRotate,
          // setIsRotate,
          isPlay={isPlay}
          orientation={orientation}
          selectedNumber={selectedNumber}
          isMobile={isMobile}
          autoModeIsStart={autoModeIsStart}
          autoModeAcceleration={autoModeAcceleration}
        />

        <RouletteNums
          submitData={submitData}
          setSubmitData={setSubmitData}
          // responseData,
          textRates={textRates}
          // isRotate,
          // setIsRotate,
          isPlay={isPlay}
          orientation={orientation}
          selectedNumber={selectedNumber}
          isMobile={isMobile}
          isOverlay={true}
          autoModeIsStart={autoModeIsStart}
          autoModeAcceleration={autoModeAcceleration}
        />
      </StyledRouletteNum>
    </StyledRouletteTable>

  );
});

export default RouletteTable;
