import { useState, useCallback, useRef, useEffect, useMemo } from "react";
import { useBetween } from 'use-between';
import { GAMES } from '../../games/Constants';
import {
  ROULETTE_DATA_TYPE_MAP,
  ROULETTE_DATA_TYPE,
  ROULETTE_KEYS_FOR_REVERSE_BET,
} from '../../games/roulette/utils';


const delay = (timeout) => new Promise((resolve) => {
  setTimeout(() => resolve(), timeout);
});

const decreaseBet = ({
  bet,
  percentValue,
  suggestedNumbers,
  game,
}) => {
  const currentValue = parseFloat(bet);
  console.log('-- DECREASE BET, currentBet:', bet);
  console.log('-- percent:', percentValue);
  const percent = parseFloat(percentValue) / 100;
  let newBet = Math.max(currentValue - (currentValue * percent), 0.00000001);
  console.log('-- DECREASE BET, newBet:', newBet);
  let newSuggestedNumbers = suggestedNumbers;
  if (game === GAMES.ROULETTE) {
    newSuggestedNumbers = { ...suggestedNumbers };
    newBet = 0;
    Object.keys(newSuggestedNumbers).forEach((key) => {
      const value = newSuggestedNumbers[key];
      newSuggestedNumbers[key] = Math.max(value - (value * percent), 0.00000001);
      newBet += newSuggestedNumbers[key];
    });
  }
  return {
    newBet,
    newSuggestedNumbers,
  }
}

const increaseBet = ({
  bet,
  percentValue,
  suggestedNumbers,
  game,
}) => {
  const currentValue = parseFloat(bet);
  console.log('-- INCREASE BET, currentBet:', bet);
  console.log('-- percent:', percentValue);
  const percent = parseFloat(percentValue) / 100;
  let newBet = Math.max(currentValue + (currentValue * percent), 0.00000001);
  console.log('-- INCREASE BET, newBet:', newBet);
  let newSuggestedNumbers = suggestedNumbers;
  if (game === GAMES.ROULETTE) {
    newSuggestedNumbers = { ...suggestedNumbers };
    newBet = 0;
    Object.keys(newSuggestedNumbers).forEach((key) => {
      const value = newSuggestedNumbers[key];
      newSuggestedNumbers[key] = Math.max(value + (value * percent), 0.00000001);
      newBet += newSuggestedNumbers[key];
    });
  }
  return {
    newBet,
    newSuggestedNumbers,
  }
}
const IS_DEBUG_AUTO_BETS = false;
const consoleLog = (...args) => {
  if (IS_DEBUG_AUTO_BETS) {
    console.log(...args);
  }
}
export const useAutoBetsStates = () => {

  const [isDisableAutoBet, setIsDisableAutoBet] = useState(false);
  const [isStartRequestStopAutoBets, setIsStartRequestStopAutoBets] = useState(false);
  const [autoModeIsStart, setAutoModeIsStart] = useState(false);
  const [game, setGame] = useState(null);
  const isFirstRun = useRef(true);

  const [autoMode, setAutoMode] = useState({
    firstBet: null,
    firstSuggestedNumbers: null,
    firstSign: null,
    acceleration: 3,
    countOfBets: 0,
    countOfLose: 0,
    countOfWins: 0,
    maxCountOfBet: undefined,
    stopByMinBalanceAmount: undefined,
    stopByMaxBalanceAmount: undefined,
    stopByMaxBetsAmount: undefined,
    lose: {
      subsequence: false,
      countOfSubsequence: 1,
      returnToBase: true,
      decrease: {
        enable: false,
        value: undefined,
      },
      increase: {
        enable: false,
        value: undefined,
      },
      swapDirection: false,
      stopAutoMode: false
    },
    wins: {
      subsequence: false,
      countOfSubsequence: 1,
      returnToBase: true,
      decrease: {
        enable: false,
        value: undefined,
      },
      increase: {
        enable: false,
        value: undefined,
      },
      swapDirection: false,
      stopAutoMode: false
    }
  });

  const gameInfoRef = useRef({
    responseData: null,
    submitData: null,
    errorData: null,
    toPlay: null,
    autoModeIsStart: null,
    isUserStop: false,
    isLeavePage: false,
    setSubmitData: null,
    setErrorData: null,
    setStopAnimation: null,
    pausedGame: {},
    isPlay: false,
    animationCount: 0,
    animationDisabled: false,
  });

  useEffect(() => {
    consoleLog('MOUNT AUTO BETS STATES');
    return () => {
      consoleLog('UNMOUNT AUTO BETS STATES');
    }
  }, []);

  useEffect(() => {
    if (!isFirstRun.current) {
      consoleLog('SAVE AUTOMODE', {
        acceleration: autoMode.acceleration,
        lose: {
          subsequence: autoMode.lose.subsequence,
        },
        wins: {
          subsequence: autoMode.wins.subsequence,
        }
      });
      localStorage.setItem("autoModePart", JSON.stringify({
        acceleration: autoMode.acceleration,
        lose: {
          subsequence: autoMode.lose.subsequence,
        },
        wins: {
          subsequence: autoMode.wins.subsequence,
        }
      }));
    }
    isFirstRun.current = false;
  }, [autoMode]);

  useEffect(() => {
    if (game) {
      gameInfoRef.current.isLeavePage = false;
      let autoModePart = localStorage.getItem('autoModePart');
      consoleLog('LOAD AUTOMODE:', autoModePart);
      if (autoModePart && autoModePart !== "undefined") {
        autoModePart = JSON.parse(autoModePart);
        if (autoModePart && autoModePart.wins) {
          setAutoMode((prevState) => {
            prevState.acceleration = autoModePart.acceleration;
            prevState.wins.subsequence = autoModePart.wins.subsequence;
            prevState.lose.subsequence = autoModePart.lose.subsequence;
            return ({
              ...prevState,
            })
          })
        }
      }
    }
  }, [game]);

  const waitChangeResponseOrError = useCallback((lastResponseId) => new Promise((resolve) => {
    consoleLog('waitChangeResponseOrError');
    const check = () => {
      if (lastResponseId !== gameInfoRef.current?.responseData?.id) {
        resolve({ error: false, terminate: false });
        return;
      }
      if (gameInfoRef.current?.errorData.error) {
        resolve({ error: true, terminate: gameInfoRef.current?.errorData.terminate });
        return;
      }
      if (gameInfoRef.current?.isLeavePage) {
        resolve({ error: true, terminate: true });
        return;
      }
      setTimeout(check, 50);
      return;
    };
    check();
  }), [gameInfoRef]);
  /*
  const waitAutoModeIsStart = useCallback(() => new Promise((resolve) => {
    const check = () => {
      if (gameInfoRef.current?.autoModeIsStart) {
        resolve();
        return;
      }
      setTimeout(check, 50);
      return;
    };
    check();
  }), [gameInfoRef]);
  */

  const getGameInfo = useCallback(() => gameInfoRef.current, [gameInfoRef]);

  // setSubmitData, setErrorData, setStopAnimation

  const setSubmitData = useCallback((...args) => {
    if (getGameInfo().setSubmitData) {
      getGameInfo().setSubmitData(...args);
    }
  }, [getGameInfo]);

  const setErrorData = useCallback((...args) => {
    if (getGameInfo().setErrorData) {
      getGameInfo().setErrorData(...args);
    }
  }, [getGameInfo]);

  const setStopAnimation = useCallback((...args) => {
    if (getGameInfo().setStopAnimation) {
      getGameInfo().setStopAnimation(...args);
    }
  }, [getGameInfo]);

  const waitDoPlayGame = useCallback(() => new Promise((resolve) => {
    consoleLog('waitDoPlayGame');
    const check = () => {
      if (!gameInfoRef.current?.isPlay) {
        resolve();
        return;
      }
      if (gameInfoRef.current.isLeavePage) {
        resolve();
        return;
      }
      setTimeout(check, 50);
      return;
    };
    check();
  }), [gameInfoRef]);

  const startAutoBets = useCallback(() => {
    getGameInfo().isUserStop = false;
    getGameInfo().isLeavePage = false;
    getGameInfo().animationCount = 0;
    (async () => {
      try {
        consoleLog('=========== START AUTO BETS =============', game);
        // setAutoModalBetsVisible(false);
        let countOfBets = 0;
        let countOfLoseSubsequence = 0;
        let countOfWinSubsequence = 0;

        let isWork = true;
        await delay(500);
        consoleLog('- autoMode:', autoMode);
        let lastResponseId = getGameInfo().responseData?.id;
        let isBetWin = undefined;
        let currentBet = Number(autoMode.firstBet);
        let currentSign = autoMode.firstSign;
        let currentSuggestedNumbers = autoMode.firstSuggestedNumbers;
        let swapDirection = false;
        let swapDirectionContext;
        setAutoModeIsStart(true);

        while (isWork) {
          swapDirection = false;
          consoleLog('- start game,lastResponseId:', lastResponseId);
          consoleLog('- balance:', getGameInfo().balance);
          if (game === GAMES.DICE) {
            // eslint-disable-next-line
            setSubmitData((prevState) => ({
              ...prevState,
              bet: Number(currentBet).toFixed(8),
              sign: currentSign,
              suggestedNumbers: `${currentSuggestedNumbers}`,
            }));
          } else if (game === GAMES.BALLS) {
            // eslint-disable-next-line
            setSubmitData((prevState) => ({
              ...prevState,
              bet: Number(currentBet).toFixed(8),
              suggestedNumbers: `${currentSuggestedNumbers}`,
            }));
          } else if (game === GAMES.ROULETTE) {
            consoleLog('- setSubmitData,currentBet.toFixed(8):', Number(currentBet).toFixed(8), currentSuggestedNumbers);
            // eslint-disable-next-line
            setSubmitData((prevState) => ({
              ...prevState,
              bet: Number(currentBet + ''),
              suggestedNumbers: currentSuggestedNumbers,
            }));
          }

          await delay(50);

          getGameInfo().toPlay(false);
          const { error, terminate } = await waitChangeResponseOrError(lastResponseId);
          if (error) {
            if (terminate) {
              isWork = false;
            }
            consoleLog('-- TERMINATE GAME ERROR REQUEST or OTHER,terminate:' + `${terminate}`)
            setErrorData({ error: false, terminate: false });
            if (isWork) {
              await delay(1000);
            }
            continue;
          }
          await waitDoPlayGame();
          countOfBets++;
          lastResponseId = getGameInfo().responseData?.id;
          isBetWin = Math.abs(parseFloat(getGameInfo().responseData?.win)) > 0;

          if (autoMode.wins.subsequence) {
            // если выбрана серия выигрышей подряд, то прибавляем победу и если проигрыш то обнуляем счетчик
            countOfWinSubsequence = isBetWin ? countOfWinSubsequence + 1 : 0;
          } else {
            // если считаем каждый выиграшь, при выигрыше прибавляем иначе оставляем тем же
            countOfWinSubsequence = isBetWin ? countOfWinSubsequence + 1 : countOfWinSubsequence;
          }

          if (autoMode.lose.subsequence) {
            // если выбрана серия проиграшей подряд, то прибавляем проиграш и если выиграшь то обнуляем счетчик
            countOfLoseSubsequence = !isBetWin ? countOfLoseSubsequence + 1 : 0;
          } else {
            // если считаем каждый проиграшь, при проиграше прибавляем иначе оставляем тем же
            countOfLoseSubsequence = !isBetWin ? countOfLoseSubsequence + 1 : countOfLoseSubsequence;
          }

          consoleLog('- IS WIN:', isBetWin);
          consoleLog('- getGameInfo().responseData:', getGameInfo().responseData);
          consoleLog('- change response,getGameInfo().responseData?.id:', getGameInfo().responseData?.id);
          consoleLog('- balance:', getGameInfo().submitData.paymentMethod?.balances?.amount);
          consoleLog('- end game,newResponseId:', getGameInfo().responseData?.id);

          if (countOfWinSubsequence === autoMode.wins.countOfSubsequence) {
            consoleLog('- COUNT OF WINS:', countOfWinSubsequence);
            countOfWinSubsequence = 0;
            if (autoMode.wins.returnToBase) {
              currentBet = autoMode.firstBet;
              if (game === GAMES.ROULETTE) {
                currentSuggestedNumbers = autoMode.firstSuggestedNumbers;
              }
            } else if (autoMode.wins.decrease.enable) {
              const { newBet, newSuggestedNumbers } = decreaseBet({
                bet: currentBet,
                percentValue: autoMode.wins.decrease.value || 0,
                suggestedNumbers: currentSuggestedNumbers,
                game,
              });
              currentBet = newBet;
              currentSuggestedNumbers = newSuggestedNumbers;
            } else if (autoMode.wins.increase.enable) {
              const { newBet, newSuggestedNumbers } = increaseBet({
                bet: currentBet,
                percentValue: autoMode.wins.increase.value || 0,
                suggestedNumbers: currentSuggestedNumbers,
                game,
              });
              currentBet = newBet;
              currentSuggestedNumbers = newSuggestedNumbers;
            }
            if (autoMode.wins.swapDirection) {
              swapDirection = true;
              swapDirectionContext = 'wins';
            }
            if (autoMode.wins.stopAutoMode) {
              consoleLog('-- STOP BY WINS COUNT');
              isWork = false;
            }
          }

          if (countOfLoseSubsequence === autoMode.lose.countOfSubsequence) {
            consoleLog('- COUNT OF LOSE:', countOfLoseSubsequence);
            countOfLoseSubsequence = 0;
            if (autoMode.lose.returnToBase) {
              currentBet = Number(autoMode.firstBet);
              if (game === GAMES.ROULETTE) {
                currentSuggestedNumbers = autoMode.firstSuggestedNumbers;
              }
            } else if (autoMode.lose.decrease.enable) {
              const { newBet, newSuggestedNumbers } = decreaseBet({
                bet: currentBet,
                percentValue: autoMode.lose.decrease.value || 0,
                suggestedNumbers: currentSuggestedNumbers,
                game,
              });
              currentBet = newBet;
              currentSuggestedNumbers = newSuggestedNumbers;
            } else if (autoMode.lose.increase.enable) {
              const { newBet, newSuggestedNumbers } = increaseBet({
                bet: currentBet,
                percentValue: autoMode.lose.increase.value || 0,
                suggestedNumbers: currentSuggestedNumbers,
                game,
              });
              currentBet = newBet;
              currentSuggestedNumbers = newSuggestedNumbers;
            }
            if (autoMode.lose.swapDirection) {
              swapDirection = true;
              swapDirectionContext = 'lose';
            }
            if (autoMode.lose.stopAutoMode) {
              consoleLog('-- STOP BY LOSE COUNT');
              isWork = false;
            }
          }

          if (swapDirection) {
            if (game === GAMES.DICE) {
              consoleLog('-- SWAP DIRECTION, currentSuggestedNumbers:', currentSuggestedNumbers);
              consoleLog('-- currentSign:', currentSign);
              if (currentSign === 1) {
                currentSuggestedNumbers = 99 - currentSuggestedNumbers;
                currentSign = 0;
              } else {
                currentSuggestedNumbers = 99 - currentSuggestedNumbers;
                currentSign = 1;
              }
              consoleLog('-- SWAP DIRECTION, currentSuggestedNumbers,NEW:', currentSuggestedNumbers);
              consoleLog('-- currentSign,NEW:', currentSign);
            } else if (game === GAMES.BALLS) {
              consoleLog('-- SWAP DIRECTION, currentSuggestedNumbers:', currentSuggestedNumbers);
              const ballsList = JSON.parse(currentSuggestedNumbers);
              const newBallsList = [];
              Array.from(Array(11).keys()).forEach((item) => {
                if (ballsList.indexOf(item) < 0) {
                  newBallsList.push(item);
                }
              },[]);
              currentSuggestedNumbers = JSON.stringify(newBallsList);
              consoleLog('-- SWAP DIRECTION, currentSuggestedNumbers,NEW:', currentSuggestedNumbers);
            } else if (game === GAMES.ROULETTE) {
              // TODO: calculate to roulette
              /*
              ROULETTE_DATA_TYPE_MAP,
              ROULETTE_DATA_TYPE,
              ROULETTE_KEYS_FOR_REVERSE_BET,
              */
              const drawnNumber = parseInt(getGameInfo().responseData?.drawnNumber+'');
              const responseSuggestedNumbers = getGameInfo().responseData?.suggestedNumbers;
              let newSuggestedNumbers = { ...currentSuggestedNumbers };
              const betForReverseArray = [];

              if (responseSuggestedNumbers) {
                const localSwapDirectionContext = swapDirectionContext;
                Object.keys(responseSuggestedNumbers).forEach((key) => {
                  const numberList = key.split(',').map((item) => parseInt(item));
                  const isWinKey = numberList.indexOf(drawnNumber) >= 0;
                  if (localSwapDirectionContext === 'lose' && !isWinKey && ROULETTE_DATA_TYPE[key]) {
                    betForReverseArray.push(ROULETTE_DATA_TYPE[key]);
                  } else if (localSwapDirectionContext === 'wins' && isWinKey && ROULETTE_DATA_TYPE[key]) {
                    betForReverseArray.push(ROULETTE_DATA_TYPE[key]);
                  }
                });
                consoleLog('-- localSwapDirectionContext:', localSwapDirectionContext);
                consoleLog('-- betForReverseArray:', betForReverseArray);
                consoleLog('-- currentBet:', Number(currentBet).toFixed(8));
                consoleLog('-- currentSuggestedNumbers:', currentSuggestedNumbers);

                betForReverseArray.forEach((betDataType) => {
                  const currentBetKey = ROULETTE_DATA_TYPE_MAP[betDataType];
                  if (!currentBetKey) {
                    return;
                  }
                  const betReverseDataTypes = ROULETTE_KEYS_FOR_REVERSE_BET[betDataType];
                  if (betReverseDataTypes && !Array.isArray(betReverseDataTypes)) {
                    const betReverseKey = ROULETTE_DATA_TYPE_MAP[betReverseDataTypes];
                    if (betReverseKey && !newSuggestedNumbers[betReverseKey]) {
                      newSuggestedNumbers[betReverseKey] = newSuggestedNumbers[currentBetKey];
                      newSuggestedNumbers[currentBetKey] = 0;
                      delete newSuggestedNumbers[currentBetKey];
                    }
                  } else if (betReverseDataTypes) {
                    betReverseDataTypes.forEach((dataType) => {
                      const betReverseKey = ROULETTE_DATA_TYPE_MAP[dataType];
                      if (betReverseKey && !newSuggestedNumbers[betReverseKey]) {
                        newSuggestedNumbers[betReverseKey] = newSuggestedNumbers[currentBetKey];
                        newSuggestedNumbers[currentBetKey] = 0;
                        delete newSuggestedNumbers[currentBetKey];
                      }
                    });
                  }
                });

                const newData = {};
                Object.keys(newSuggestedNumbers).forEach((key) => {
                  if(newSuggestedNumbers[key] >= 0) {
                    newData[key] = newSuggestedNumbers[key];
                  }
                });
                currentSuggestedNumbers = newData;
                consoleLog('-- NEW REVERSE BET currentSuggestedNumbers:', currentSuggestedNumbers);
                let bet = 0;
                const localCurrentSuggestedNumbers = currentSuggestedNumbers;
                Object.keys(localCurrentSuggestedNumbers).forEach((key) => {
                  if (localCurrentSuggestedNumbers[key] >= 0) {
                    bet = bet + localCurrentSuggestedNumbers[key];
                  }
                });
                consoleLog('-- NEW REVERSE BET currentBet:', bet.toFixed(8));
                currentBet = bet;
              }
              /*
              reverseBet: function(t) {
                   if (0 < Game.keysArray[t]) {
                       if (void 0 !== Game.betArray[Game.keysArray[t]] && 0 < Game.betArray[Game.keysArray[t]])
                           return !1;
                       Game.betArray[Game.keysArray[t]] = Game.betArray[t],
                       Game.betArray[t] = 0,
                       $(".cell-bet[data-type=" + Game.keysArray[t] + "] .chip-cont").html($(".cell-bet[data-type=" + t + "] .chip-cont").html()),
                       $(".cell-bet[data-type=" + t + "] .chip-cont").html("")
                   } else
                       $.each(Game.keysArray[t], function(e, a) {
                           if (!Game.betArray[a])
                               return Game.betArray[a] = Game.betArray[t],
                               Game.betArray[t] = 0,
                               $(".cell-bet[data-type=" + a + "] .chip-cont").html($(".cell-bet[data-type=" + t + "] .chip-cont").html()),
                               $(".cell-bet[data-type=" + t + "] .chip-cont").html(""),
                               !1
                       })
               },
              */
            }
          }
          consoleLog('-- countOfBets:', countOfBets);
          consoleLog('-- autoMode.maxCountOfBet:', autoMode.maxCountOfBet);
          if (autoMode.maxCountOfBet && countOfBets >= autoMode.maxCountOfBet) {
            console.log('-- STOP BY COUNT BETS');
            isWork = false;
          }

          const balanceAmount = getGameInfo().balance;
          const stopByMinBalanceAmount = parseFloat(autoMode.stopByMinBalanceAmount);
          consoleLog('-- balanceAmount:', balanceAmount);
          consoleLog('-- stopByMinBalanceAmount:', stopByMinBalanceAmount);
          if (stopByMinBalanceAmount > 0 && balanceAmount < stopByMinBalanceAmount) {
            console.log('-- STOP BY MIN BALANCE');
            isWork = false;
          }

          const stopByMaxBalanceAmount = parseFloat(autoMode.stopByMaxBalanceAmount);
          consoleLog('-- stopByMaxBalanceAmount:', stopByMaxBalanceAmount);
          if (stopByMaxBalanceAmount > 0 && balanceAmount > stopByMaxBalanceAmount) {
            console.log('-- STOP BY MAX BALANCE');
            isWork = false;
          }

          const stopByMaxBetsAmount = parseFloat(autoMode.stopByMaxBetsAmount);
          consoleLog('-- stopByMaxBetsAmount:', stopByMaxBetsAmount);
          if (stopByMaxBetsAmount > 0 && currentBet > stopByMaxBetsAmount) {
            console.log('-- STOP BY MAX BET');
            isWork = false;
          }

          if (!getGameInfo().autoModeIsStart) {
            consoleLog('-- STOP BY USER,getGameInfo().isLeavePage:', getGameInfo().isLeavePage);
            isWork = false;
          }

          if (getGameInfo().isUserStop) {
            getGameInfo().isUserStop = false;
            consoleLog('-- STOP BY USER 2');
            isWork = false;
          }

          if (getGameInfo().isLeavePage && isWork) {
            consoleLog('SAVE PAUSED GAME:', game);
            getGameInfo().pausedGame = {};
            getGameInfo().pausedGame[game] = {
              autoMode,
              currentBet,
              currentSuggestedNumbers,
              currentSign,
            };
            getGameInfo().isLeavePage = false;
            //getGameInfo().autoModeIsStart = false;
            consoleLog('-- STOP BY LEAVE PAGE');
            isWork = false;
          }
          /*
          if (isWork) {
            setIsPlay(true);
          }
          */
          let delayNext = 60;
          if (autoMode.acceleration === 3) {
            delayNext = 60;
          } else if (autoMode.acceleration === 2) {
            delayNext = 100;
          } else if (autoMode.acceleration === 1) {
            delayNext = 200;
          } else {
            delayNext = 300;
          }
          if (isWork) {
            await delay(delayNext);
          }
        }
        await delay(1000);
        if (setStopAnimation) {
          setStopAnimation(false);
        }

        if (getGameInfo().animationDisabled) {
          getGameInfo().animationCount = 0;
          if (getGameInfo().resetBet) {
            getGameInfo().resetBet();
          }
        } else {
          console.log('getGameInfo().animationCount:', getGameInfo().animationCount);
          if (getGameInfo().resetBet && getGameInfo().animationCount === 0) {
            getGameInfo().resetBet();
          }
        }
        getGameInfo().isLeavePage = false;
        setAutoModeIsStart(false);
        getGameInfo().autoModeIsStart = false;
        consoleLog('============= END AUTO BETS ==============');
      } catch (error) {
        console.log('error:', error);
      }
    })();
    return true;
  }, [
    autoMode,
    setAutoModeIsStart,
    waitChangeResponseOrError,
    getGameInfo,
    game,
    setSubmitData,
    setErrorData,
    setStopAnimation,
    waitDoPlayGame,
    // waitAutoModeIsStart,
  ]);

  return {
    autoModeIsStart,
    setAutoModeIsStart,
    isStartRequestStopAutoBets,
    setIsStartRequestStopAutoBets,
    setAutoMode,
    autoMode,
    setGame,
    game,
    startAutoBets,
    gameInfoRef,
    isDisableAutoBet,
    setIsDisableAutoBet,
  };
};

const AutoBetsStatesBalls = () => {
  const autoBetsData = useAutoBetsStates();
  return autoBetsData;
}

export const useAutoBetsStatesBalls = () => useBetween(AutoBetsStatesBalls);

const AutoBetsStatesDice = () => {
  const autoBetsData = useAutoBetsStates();
  return autoBetsData;
}

export const useAutoBetsStatesDice = () => useBetween(AutoBetsStatesDice);

const AutoBetsStatesRoulette = () => {
  const autoBetsData = useAutoBetsStates();
  return autoBetsData;
}

export const useAutoBetsStatesRoulette = () => useBetween(AutoBetsStatesRoulette);
