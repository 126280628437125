export const calculateChance = (suggestedNumbers, defaultRouletteSettings = null) => {
  /*
  if (!defaultRouletteSettings) {
    defaultRouletteSettings = settings;
  }
  */
  let chance = suggestedNumbers.length / (defaultRouletteSettings.max + 1) * 100;
  return parseInt(chance * 100) / 100;
};

export const calculateCoefficient = (suggestedNumbers, defaultBallsSettings = null) => {
  /*
  if (!defaultBallsSettings) {
    defaultBallsSettings = settings;
  }
  */
  let coefficient = (defaultBallsSettings.max + 1) / suggestedNumbers.length - ((defaultBallsSettings.max + 1) / suggestedNumbers.length * defaultBallsSettings.profit) / 100;
  return parseInt(coefficient * 10000) / 10000;
};

export const calculateWinAmount = (bet, coefficient) => {
  return (bet * coefficient - bet).toFixed(6);
};

export const fetchSuggestedNumbers = (suggestedNumbersData) => {
  let tmpNumbersData = [];
  for (const [key, value] of Object.entries(suggestedNumbersData)) {
    let numbers = key.split(",");

    let c = tmpNumbersData.concat(numbers);
    tmpNumbersData = c.filter((item, pos) => c.indexOf(item) === pos);
  }
  return tmpNumbersData;
};

export const formatChip = (value, color) => {
  if (value == null || typeof value !== 'number') {
    return '';
  }
  const chipsValue = parseFloat((value / 0.00000001).toFixed(8));

  if (!color) {
    if (chipsValue >= 0 && chipsValue < 1000000) {
      color = "red";
    } else if (chipsValue >= 1000000 && chipsValue < 1000000000000) {
      color = "green";
    } else if (chipsValue >= 1000000000000) {
      color = "yellow";
    }
  }

  const formatNumber = (num, suffix) => {
    if (num >= 100) {
      return Math.floor(num) + suffix;
    } else if (num >= 10) {
      return (Math.floor(num * 10) / 10).toFixed(1).replace(/\.0$/, '') + suffix;
    } else {
      return num.toFixed(2).replace(/\.?0+$/, '') + suffix;
    }
  };

  if (chipsValue < 1000) {
    return chipsValue.toFixed(0);
  } else if (chipsValue < 1000000) {
    return formatNumber(chipsValue / 1000, 'k');
  } else if (chipsValue < 1000000000) {
    return formatNumber(chipsValue / 1000000, 'M');
  } else if (chipsValue < 1000000000000) {
    return formatNumber(chipsValue / 1000000000, 'B');
  } else if (chipsValue < 1000000000000000) {
    return formatNumber(chipsValue / 1000000000000, 'T');
  } else {
    return formatNumber(chipsValue / 1000000000000000, 'Q');
  }
};

export const ROULETTE_DATA_TYPE_MAP = {
  1: '0',
  2: '3',
  3: '6',
  4: '9',
  5: '12',
  6: '15',
  7: '18',
  8: '21',
  9: '24',
  10: '27',
  11: '30',
  12: '33',
  13: '36',
  14: '3,6,9,12,15,18,21,24,27,30,33,36',
  15: '2',
  16: '5',
  17: '8',
  18: '11',
  19: '14',
  20: '17',
  21: '20',
  22: '23',
  23: '26',
  24: '29',
  25: '32',
  26: '35',
  27: '2,5,8,11,14,17,20,23,26,29,32,35',
  28: '1',
  29: '4',
  30: '7',
  31: '10',
  32: '13',
  33: '16',
  34: '19',
  35: '22',
  36: '25',
  37: '28',
  38: '31',
  39: '34',
  40: '1,4,7,10,13,16,19,22,25,28,31,34',
  41: '1,2,3,4,5,6,7,8,9,10,11,12',
  42: '13,14,15,16,17,18,19,20,21,22,23,24',
  43: '25,26,27,28,29,30,31,32,33,34,35,36',
  44: '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18',
  45: '2,4,6,8,10,12,14,16,18,20,22,24,26,28,30,32,34,36',
  46: '2,4,6,8,10,11,13,15,17,20,22,24,26,28,29,31,33,35',
  47: '1,3,5,7,9,12,14,16,18,19,21,23,25,27,30,32,34,36',
  48: '1,3,5,7,9,11,13,15,17,19,21,23,25,27,29,31,33,35',
  49: '19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36',
  50: '0,3',
  51: '3,6',
  52: '6,9',
  53: '9,12',
  54: '12,15',
  55: '15,18',
  56: '18,21',
  57: '21,24',
  58: '24,27',
  59: '27,30',
  60: '30,33',
  61: '33,36',
  62: '0,2,3',
  63: '2,3',
  64: '2,3,5,6',
  65: '5,6',
  66: '5,6,8,9',
  67: '8,9',
  68: '8,9,11,12',
  69: '11,12',
  70: '11,12,14,15',
  71: '14,15',
  72: '14,15,17,18',
  73: '17,18',
  74: '17,18,20,21',
  75: '20,21',
  76: '20,21,23,24',
  77: '23,24',
  78: '23,24,26,27',
  79: '26,27',
  80: '26,27,29,30',
  81: '29,30',
  82: '29,30,32,33',
  83: '32,33',
  84: '32,33,35,36',
  85: '35,36',
  86: '0,2',
  87: '2,5',
  88: '5,8',
  89: '8,11',
  90: '11,14',
  91: '14,17',
  92: '17,20',
  93: '20,23',
  94: '23,26',
  95: '26,29',
  96: '29,32',
  97: '32,35',
  98: '0,1,2',
  99: '1,2',
  100: '1,2,4,5',
  101: '4,5',
  102: '4,5,7,8',
  103: '7,8',
  104: '7,8,10,11',
  105: '10,11',
  106: '10,11,13,14',
  107: '13,14',
  108: '13,14,16,17',
  109: '16,17',
  110: '16,17,19,20',
  111: '19,20',
  112: '19,20,22,23',
  113: '22,23',
  114: '22,23,25,26',
  115: '25,26',
  116: '25,26,28,29',
  117: '28,29',
  118: '28,29,31,32',
  119: '31,32',
  120: '31,32,34,35',
  121: '34,35',
  122: '0,1',
  123: '1,4',
  124: '4,7',
  125: '7,10',
  126: '10,13',
  127: '13,16',
  128: '16,19',
  129: '19,22',
  130: '22,25',
  131: '25,28',
  132: '28,31',
  133: '31,34',
  134: '0,1,2,3',
  135: '1,2,3',
  136: '1,2,3,4,5,6',
  137: '4,5,6',
  138: '4,5,6,7,8,9',
  139: '7,8,9',
  140: '7,8,9,10,11,12',
  141: '10,11,12',
  142: '10,11,12,13,14,15',
  143: '13,14,15',
  144: '13,14,15,16,17,18',
  145: '16,17,18',
  146: '16,17,18,19,20,21',
  147: '19,20,21',
  148: '19,20,21,22,23,24',
  149: '22,23,24',
  150: '22,23,24,25,26,27',
  151: '25,26,27',
  152: '25,26,27,28,29,30',
  153: '28,29,30',
  154: '28,29,30,31,32,33',
  155: '31,32,33',
  156: '31,32,33,34,35,36',
  157: '34,35,36',
}

const rouletteDataType = {};
Object.keys(ROULETTE_DATA_TYPE_MAP).forEach((key) => {
  rouletteDataType[ROULETTE_DATA_TYPE_MAP[key]] = key;
});
export const ROULETTE_DATA_TYPE = rouletteDataType;

export const ROULETTE_KEYS_FOR_REVERSE_BET = {
  2: [6,10],
  3: [7,11],
  4: [8,12],
  5: [9,13],
  6: [10,2],
  7: [11,3],
  8: [12,4],
  9: [13,5],
  10: [2,6],
  11: [3,7],
  12: [4,8],
  13: [5,9],
  14: [27,40],
  15: [19,23],
  16: [20,24],
  17: [21,25],
  18: [22,26],
  19: [23,15],
  20: [24,16],
  21: [25,17],
  22: [26,18],
  23: [15,19],
  24: [16,20],
  25: [17,21],
  26: [18,22],
  27: [40,14],
  28: [32,36],
  29: [33,37],
  30: [34,38],
  31: [35,39],
  32: [36,28],
  33: [37,29],
  34: [38,30],
  35: [39,31],
  36: [28,32],
  37: [29,33],
  38: [30,34],
  39: [31,35],
  40: [14,27],
  41: [42,43],
  42: [43,41],
  43: [41,42],
  44: 49,
  45: 48,
  46: 47,
  47: 46,
  48: 45,
  49: 44,
  50: [54,58],
  51: [55,59],
  52: [56,60],
  53: [57,61],
  54: [58,50],
  55: [59,51],
  56: [60,52],
  57: [61,53],
  58: [50,54],
  59: [51,55],
  60: [52,56],
  61: [53,57],
  62: [70,78],
  63: [71,79],
  64: [72,80],
  65: [73,81],
  66: [74,82],
  67: [75,83],
  68: [76,84],
  69: [77,85],
  70: [78,62],
  71: [79,63],
  72: [80,64],
  73: [81,65],
  74: [82,66],
  75: [83,67],
  76: [84,68],
  77: [85,69],
  78: [62,70],
  79: [63,71],
  80: [64,72],
  81: [65,73],
  82: [66,74],
  83: [67,75],
  84: [68,76],
  85: [69,77],
  86: [90,94],
  87: [91,95],
  88: [92,96],
  89: [93,97],
  90: [94,86],
  91: [95,87],
  92: [96,88],
  93: [97,89],
  94: [86,90],
  95: [87,91],
  96: [88,92],
  97: [89,93],
  98: [106,114],
  99: [107,115],
  100: [108,116],
  101: [109,117],
  102: [110,118],
  103: [111,119],
  104: [112,120],
  105: [113,121],
  106: [114,98],
  107: [115,99],
  108: [116,100],
  109: [117,101],
  110: [118,102],
  111: [119,103],
  112: [120,104],
  113: [121,105],
  114: [98,106],
  115: [99,107],
  116: [100,108],
  117: [101,109],
  118: [102,110],
  119: [103,111],
  120: [104,112],
  121: [105,113],
  122: [126,130],
  123: [127,131],
  124: [128,132],
  125: [129,133],
  126: [130,122],
  127: [131,123],
  128: [132,124],
  129: [133,125],
  130: [122,126],
  131: [123,127],
  132: [124,128],
  133: [125,129],
  134: [142,150],
  135: [143,151],
  136: [144,152],
  137: [145,153],
  138: [146,154],
  139: [147,155],
  140: [148,156],
  141: [149,157],
  142: [150,134],
  143: [151,135],
  144: [152,136],
  145: [153,137],
  146: [154,138],
  147: [155,139],
  148: [156,140],
  149: [157,141],
  150: [134,142],
  151: [135,143],
  152: [136,144],
  153: [137,145],
  154: [138,146],
  155: [139,147],
  156: [140,148],
  157: [141,149],
};
