import { games } from "./consts";

export const getCurrentGame = () => {

  switch (true) {
    case null:
    case localStorage.getItem("gamePage")?.includes(games.balls):
      return games.balls;
    case localStorage.getItem("gamePage")?.includes(games.roulette):
      return games.roulette;
    default:
      return games.dice;
  }

};