import { useState } from 'react';
import { CustomSelectStyle } from "./CustomSelectStyle";

const CustomSelect = ({ options, setValue }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(options[0]);

    const toggling = () => setIsOpen(!isOpen);

    function onOptionClicked(e, option, index) {
        e.stopPropagation();
        setSelectedOption(option);
        setIsOpen(false);
        setValue(index);
    }

    return (
        <CustomSelectStyle className={isOpen ? "focus" : ""} onClick={toggling}>
            <span className={`custom-select-header`}>
                {selectedOption}
            </span>
            {isOpen && (
                <div className="custom-select-list">
                    {options.map((option, index) => (
                        <div onClick={(e) => onOptionClicked(e, option, index)} className={`custom-select-list-option ${option === selectedOption ? "selected" : ""}`} key={option}>
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </CustomSelectStyle>
    );
}

export default CustomSelect;