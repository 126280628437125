import React, {useContext, useState} from "react";
import {useTranslation} from 'react-i18next';
import {StyledHotkeysToggler} from "./styledHotkeysToggler";
import HotkeysTogglerModal from "./HotkeysTogglerModal";
import {AppContext} from "../../../App";

const HotkeysToggler = ({game}) => {
    const {t} = useTranslation('siteOptions');

    const [hotkeysModalVisible, setHotkeysModalVisible] = useState(false);

    const {hotkeys} = useContext(AppContext);
    const {hotkeysDisabled, setHotkeysDisabled} = hotkeys;

    const openHotkeysModal = () => {
        if (hotkeysDisabled) setHotkeysModalVisible(true);
        window.localStorage.setItem('hotkeysDisabled', JSON.stringify(!hotkeysDisabled));
        setHotkeysDisabled(!hotkeysDisabled);
    }

    return (
        <>
            <StyledHotkeysToggler hotkeysDisabled={hotkeysDisabled}
                                  onClick={openHotkeysModal}>
                <span className="icon hotkeys-icon"/>
                <span className="text">{t("hotkeysButton")}</span>
            </StyledHotkeysToggler>
            <HotkeysTogglerModal isVisible={hotkeysModalVisible} setIsVisible={setHotkeysModalVisible} game={game}/>
        </>
    );
};

export default HotkeysToggler;