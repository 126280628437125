import {chatEmoji} from "./chatEmoji";
import DOMPurify from "dompurify";

export const encodeMessage = (htmlString) => {
    const parser = new DOMParser();

    const messageWrapper = parser.parseFromString(htmlString, 'text/html')?.body

    for (const child of messageWrapper.children) {
        const dataCode = child.getAttribute('data-code')

        if (dataCode) {
            child.src = dataCode
            child.removeAttribute('data-code')
            child.removeAttribute('width')
        }
    }

    return messageWrapper.innerHTML
}

export const decodeMessage = (messageForDecode) => {
    const parser = new DOMParser();

    const sanitizedMessage = DOMPurify.sanitize(messageForDecode);

    const messageWrapper = parser.parseFromString(sanitizedMessage, 'text/html')?.body

    for (const child of messageWrapper.children) {
        const imageSrc = child.getAttribute('src')
        const currentEmoji = chatEmoji.find((emoji) => emoji.code === imageSrc)

        if (currentEmoji) {
            child.src = currentEmoji.icon
            child.width = 24
        }
    }

    return messageWrapper.innerHTML
}