import styled from "styled-components";
import google from "../../assets/images/icon-google.svg"

export const StyledAuthForm = styled.div`
  .auth-action {
    display: grid;
  }
  a.default-link {
    color: ${({theme}) => theme.subTitleColor};
    border-bottom: 1px dashed ${({theme}) => theme.subTitleColor};
    &:hover {
      color: ${({theme}) => theme.defaultChangeColor};
      border-bottom: 1px dashed ${({theme}) => theme.defaultChangeColor};
    }
  }
`;

export const StyledAuthWithSocialWrapper = styled.div`
  padding: 25px 0 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  text-align: center;
  button {
    width: 218px;
    height: 40px;
    margin: 0 auto;
    font-size: 16px;
    text-transform: inherit;
    background-color: #222222;
    border-color: #222222;
    border-radius: 20px;
    &:hover {
      background-color: #2b2b2b;
      border-color: #2b2b2b;
    }
  }
  span {
    padding-left: 35px;
    position: relative;
    &:before {
      content: '';
      height: 20px;
      width: 20px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  span.with-google:before {
    background-image: url(${google});
  }
`;

export const StyledSocialButtonWrapper = styled.div`
 
`;

export const StyledAuthText = styled.div`
  color: #8C9B9B;
  font-size: ${({ size }) => size + "px" || "16px"};
  text-align: center;
`;

export const StyledOrAuthBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;  
  margin-top: 25px;  
`;

export const StyledOrAuthLine = styled.div`
  height: 2px;
  background-color: ${({theme}) => theme.labelColor};
  width: 100%;  
    
  &:first-child {
    margin-right: 15px;  
  }
    
  &:last-child {
    margin-left: 15px;
  }
`;

export const StyledOrAuthText = styled.span`
  color: ${({theme}) => theme.labelColor};
  flex-shrink: 0;
`;

export const StyledGoogleButton = styled.button`
  background: ${({theme}) => theme.authBtn.backgroundColor} !important;
  box-shadow: none !important;  
  outline: none !important;
  border: 1px solid ${({theme}) => theme.authBtn.borderColor} !important;
  opacity: 1 !important;
  border-radius: 5px !important;  
  display: flex;  
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 44px !important;

  img {
    height: 20px;
  } 
    
  &:hover {
    background: ${({theme}) => theme.authBtn.backgroundHover} !important;
    border: 1px solid ${({theme}) => theme.authBtn.borderHover} !important;
  }
`;

export const StyledTelegramButton = styled.button`
  background: ${({theme}) => theme.authBtn.backgroundColor} !important;
  box-shadow: none !important;  
  outline: none !important;
  border: 1px solid ${({theme}) => theme.authBtn.borderColor} !important;  
  opacity: 1 !important;
  border-radius: 5px !important;  
  display: flex;  
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 44px !important;
    
  img {
    height: 20px;
  }
    
  &:hover {
    background: ${({theme}) => theme.authBtn.backgroundHover} !important;
    border: 1px solid ${({theme}) => theme.authBtn.borderHover} !important;
  }  
`;