import React, {useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import InputGroup from "../elements/inputGroup/InputGroup";
import LoadButton from "../elements/spinner/ButtonSpinner";
import AlertMessage from "../elements/alert/Alert";
import { StyledAuthForm } from "../login/styledLogin";
import { StyledButton } from "../styles/styledButton";
import ReCaptcha from "../elements/reCaptcha/ReCaptcha";
import axios from "axios";
import {responseStatus} from "../../utils/consts";
import {closableNotification} from "../elements/notification/ClosableNotification";
import {useBetween} from "use-between";
import LanguageStates from "../elements/listLanguage/LanguageStates";

const ForgotPassword = ({ setVisible }) => {
    const { t } = useTranslation('header');
    const { t: tO } = useTranslation('siteOptions');
    const {t: tE} = useTranslation('errors');

    const { activeLang } = useBetween(LanguageStates);

    const [loading, setLoading] = useState(false);

    const recaptchaRef = useRef(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const captchaToken = recaptchaRef?.current?.getValue();
        if (!captchaToken) return closableNotification(tE("Failed authentication, suspicious activity"), "error");
        recaptchaRef.current.reset();
        setLoading(true);
        await axios.post("/api/users/forgot-password", { email: event.target.email.value, language: activeLang }).then(response => {
            if (response.status === responseStatus.HTTP_OK) {
                closableNotification(tO('forgotYourPasswordSuccess'), "success");
                setLoading(false);
                setVisible("");
            }
        }).catch(error => {
            closableNotification(error.response.data.error, "error");
            setLoading(false);
        });
    };

    return (
        <StyledAuthForm>
            <form onSubmit={handleSubmit}>
                <InputGroup
                    id="email"
                    type="email"
                    label={t('enterEmail')}
                    name="email"
                    required
                />
                <ReCaptcha recaptchaRef={recaptchaRef}/>
                <AlertMessage
                    mt="25"
                    mb="25"
                    message={tO("forgotYourPasswordText")}
                />
                <div className="auth-action">
                    {!loading ?
                        <StyledButton
                            color="neutral"
                            type="submit"
                        >
                            {tO('send')}
                        </StyledButton> :
                        <LoadButton
                            text={"ㅤ"}
                            color="neutral"
                        />}
                </div>

            </form>
        </StyledAuthForm>
    );
};

export default ForgotPassword;
