const MyStorage = sessionStorage || localStorage;

export const setItem = (key, data) => {
  let newData = data;
  if (data && typeof data === 'object') {
    newData = JSON.stringify(data);
  }
  MyStorage.setItem(key, newData);
}

export const getItem = (key, defaultValue = null) => {
  try {
    let data = MyStorage.getItem(key);
    if (data && data !== "undefined") {
      data = JSON.parse(data);
    }
    return data || defaultValue;
  } catch (error) {
    // error
    console.log(error);
    return defaultValue;
  }
}
