import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { responseStatus, waitTimer } from "../../../../utils/consts";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import { StyledButton } from "../../../styles/styledButton";
import { closableNotification } from "../../notification/ClosableNotification";
import ReCaptcha from "../../reCaptcha/ReCaptcha";
import DaysBlock from "./DaysBlock";
import {
  StyledAlertAmountUser,
  StyledHelpElementAlertAmountUser,
  StyledPopupAuthenticator, StyledSecondGetTokenWaitBlock,
  StyledTokenBonusWrapper
} from "./styledTokenBonus";
import { Trans, useTranslation } from "react-i18next";
import { StyledSpan } from "../../../styles/styledTypography";

const TokenBonus = ({ visible, setVisible, tokenBonusData, timeDifference, setBonusData, fetchTokenBonusData }) => {
  const { t } = useTranslation("leftSidebar");

  const recaptchaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(timeDifference <= waitTimer.ZERO ? 0 : (timeDifference || waitTimer.MAX));

  const getBonusTokens = (captchaToken) => {
    if (!captchaToken) {
      return;
    }

    setLoading(true);

    axios.post("/api/token-bonuses", { captchaToken }, userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_CREATED) {
        setVisible(false);
        closableNotification("Token bonus was received", "success");
      }
    }).catch(error => {
      closableNotification(error.response.data.detail, "error");
    }).finally(() => setLoading(false));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const captchaToken = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();

    getBonusTokens(captchaToken);
  };

  const { balance, token } = tokenBonusData;

  useEffect(() => {
    if (seconds > waitTimer.ZERO) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - waitTimer.INTERVAL);
      }, waitTimer.INTERVAL_MS);

      return () => clearInterval(timer);
    }
  }, [seconds]);

  return (
    <StyledTokenBonusWrapper>
      <DaysBlock token={token} setBonusData={setBonusData} fetchTokenBonusData={fetchTokenBonusData} t={t}/>
      <StyledAlertAmountUser>
        {t("yourBonus")}: {balance} LUCKY
        <StyledHelpElementAlertAmountUser>
          ?
          <div className="help-el__text">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ratione voluptatem, ipsam eum repellendus maxime
            odio numquam hic quod quia nostrum.
          </div>
        </StyledHelpElementAlertAmountUser>
      </StyledAlertAmountUser>
      <ReCaptcha recaptchaRef={recaptchaRef} />
      <StyledPopupAuthenticator>
        {t("tokenBonusAlertInfo")}
      </StyledPopupAuthenticator>
      <div className="confirm-action">
        {seconds > 0 ?
          <StyledSecondGetTokenWaitBlock>
            <Trans components={{ span: <StyledSpan /> }}>
              {t("getTokenWait", { seconds: seconds })}
            </Trans>
          </StyledSecondGetTokenWaitBlock> :
          <StyledButton
            disabled={loading}
            mt={20}
            color="main"
            type="submit"
            width="100"
            onClick={handleSubmit}
          >Get token</StyledButton>}
      </div>
    </StyledTokenBonusWrapper>
  );
};

export default TokenBonus;