import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import WrapperListLanguage from "../listLanguage/WrapperListLanguage";
import { generatePath } from "../../../utils/getLanguage";

import { StyledFooterContainer, StyledFooterNav, StyledFooterWrapper } from "./styledFooter";
import english from "../../../assets/images/flags/en.png";
import russian from "../../../assets/images/flags/ru.png";
import { useBetween } from "use-between";
import LanguageStates from "../listLanguage/LanguageStates";

const iconLang = {
  ru: russian,
  en: english
}

const Footer = () => {
  const { t } = useTranslation("footer");
  const [isActiveLang, setIsActiveLang] = useState(false);
  const { activeLang } = useBetween(LanguageStates);

  const setActiveLangBlock = () => setIsActiveLang(!isActiveLang);

  return (
    <StyledFooterWrapper>
      <StyledFooterContainer>
        <div className="footer-info">
          <div className="footer-info__left">
            Luckygames.app © {new Date().getFullYear()}
          </div>
          <div className="footer__right">
            <div className="footer-lang__active" onClick={setActiveLangBlock}>
              <img src={activeLang ? iconLang[activeLang] : iconLang.en} alt="lang"/>
            </div>
            <WrapperListLanguage isActiveLang={isActiveLang} setIsActiveLang={setIsActiveLang}/>
          </div>
        </div>
        <StyledFooterNav>
          <ul>
            <li>
              <NavLink to={generatePath('/support')}>
                {t('support')}
              </NavLink>
            </li>
            <li>
              <NavLink to={generatePath('/politic')}>
                {t('politic')}
              </NavLink>
            </li>
            <li>
              <NavLink to={generatePath('/wallets')}>
                {t('wallets')}
              </NavLink>
            </li>
            <li>
              <NavLink to={generatePath('/faq')}>
                {t('faq')}
              </NavLink>
            </li>
          </ul>
          <ul>
            <li>
              <a href={'https://t.me/luckygames_official'} target={'_blank'}>Telegram</a>
            </li>
          </ul>
        </StyledFooterNav>
      </StyledFooterContainer>
    </StyledFooterWrapper>
  );
};

export default Footer;