import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import AdminHeader from "../components/elements/header/AdminHeader";
import Sidebar from "../components/elements/sidebar/Sidebar";
import PageSpinner from "../components/elements/spinner/PageSpinner";

import LoadRoute from "../components/loadRouter/LoadRoute";

import { StyledAdminWrapper } from "../components/styles/styledContainer";

const AdminWrapper = ({ routes }) => {
  return (
    <div>
      <AdminHeader />
      <StyledAdminWrapper>
        <Sidebar />
        <Suspense fallback={<PageSpinner />}>
          <Switch>
            {routes.map((route, i) => (
              <LoadRoute key={i} {...route} />
            ))}
            <Redirect to="/page-not-found" />
          </Switch>
        </Suspense>
      </StyledAdminWrapper>
    </div>
  );
};

export default AdminWrapper;