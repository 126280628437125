import styled from "styled-components";

export const StyledWrapper = styled.div`
  ${({ visible }) => visible ? "display: flex" : "display: none"};
  position: absolute;
  flex-direction: column;
  ${({ isGame }) => isGame && "left: 65%; top: -38px;"};
  ${({ isRain }) => isRain && "left: 70%; top: -58px;"};
  ${({ isTip }) => isTip && "left: 70%; top: -58px;"};
  ${({ isReferrals }) => isReferrals && "left: 0; top: -12px !important;"};
  ${({ isBonus }) => isBonus && "z-index: 9; left: 0 !important;"};
  ${({ posStyles }) => posStyles};
  background: ${({ theme }) => theme.elementBg};
  font-size: 16px;
  line-height: 16px;
  letter-spacing: normal;
  padding: 5px 0;
  border-radius: 5px;
  border: 1px solid #ff9001;
  z-index: 10;
  white-space: normal;

  a {
    color: ${({ theme }) => theme.secondTextColor};
    white-space: nowrap;
    margin: 0 12px;
    padding: 7px 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

    &:hover {
      color: ${({ theme }) => theme.text};
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.neutralColor};
    }
  }

  &:after {
    content: '';
    position: absolute;
    ${({ isChat, arrowStyles, isBonus }) => (isChat || isBonus) ? arrowStyles : 'top: 72px; left: -20px;'};
    border-width: 10px;
    border-style: solid;
    border-color: transparent ${({ arrowStyles }) => arrowStyles.left ? '#ff9001' : 'transparent'} transparent ${({ arrowStyles }) => arrowStyles.right ? '#ff9001' : 'transparent'};
  }
`;

export const StyledWrapperPaymentItem = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(45px, 1fr));
  width: 100%;
  margin-top: 10px;
  padding-bottom: 18px;
`;

export const StyledPaymentMethodItem = styled.label`
  margin: 1px;
  width: auto;
  height: 45px;
  padding: 10px;
  vertical-align: middle;
  ${({theme}) => theme.sentTipPaymentItem};
  border-radius: 3px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
     ${({theme}) => theme.sentTipPaymentItemActive};
  }
}

  &.active {
    ${({theme}) => theme.sentTipPaymentItemActive};
  }
`;