import {useTranslation} from "react-i18next";
import {
    StyledBonusRulesBlockBlock,
    StyledBonusRulesBlockList,
    StyledBonusRulesDialog, StyledLimitBlock, StyledMainBlock
} from "./styledBonusRulesModal";
import {StyledTextBlock} from "../../siteOptions/styledSiteOptions";
import {StyledButton} from "../../../styles/styledButton";
import axios from "axios";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import {responseStatus} from "../../../../utils/consts";
import React, {useCallback, useEffect, useState} from "react";
import {StyledModalLoader} from "../../payment/styledPaymentInvoice";
import loadingGif from "../../../../assets/images/loading.gif";

function BonusRulesModal({setVisible, visible, setBonusVisible}) {
    const {t} = useTranslation("siteOptions");

    const [bonusInfoData, setBonusInfoData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!visible) return;
        getBonusData();
    }, [visible])

    const getBonusData = () => {
        axios.get(`/api/deposit-bonuses`, userAuthenticationConfig())
            .then(response => {
                if (response.status === responseStatus.HTTP_OK) {
                    setBonusInfoData(response.data["hydra:member"]);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setVisible(false);
            })
    };

    const getNumberEnd = useCallback((number) => {
        switch (number) {
            case 1:
                return t("bonusRules.endingNumberOne");
            case 2:
                return t("bonusRules.endingNumberTwo");
            case 3:
                return t("bonusRules.endingNumberThree");
            case 4:
                return t("bonusRules.endingNumberFour");
            default:
                return t("bonusRules.endingNumberOne");
        }
    }, [bonusInfoData])

    return (
        <StyledBonusRulesDialog
            visible={visible}
            wrapClassName="default-modal-wrapper"
            onClose={() => setVisible(false)}
            animation="zoom"
            maskAnimation="fade"
            title={t("bonusRules.titleModal")}
            forceRender={false}
            className="default-modal default-modal-bonus"
        >
            {isLoading ?<StyledModalLoader>
                <img src={loadingGif} alt={"loading..."}/>
            </StyledModalLoader> :<>
            <StyledBonusRulesBlockList>
                {bonusInfoData.map((item, index) => (
                    <StyledBonusRulesBlockBlock key={index}>
                        <StyledMainBlock>
                            <span className={"percent"}>{item.percent}%</span>
                            <span className={"title"}>{t("bonusRules.titleMainBlock")}</span>
                            <span
                                className={"deposit-number"}>{item?.depositPlace}{getNumberEnd(item?.depositPlace)} {t("bonusRules.titleDeposit")}</span>
                        </StyledMainBlock>
                        <StyledLimitBlock>
                            <span className={"title-limit"}>{t("bonusRules.minimumLimitTitle")}</span>
                            <span className={"title-value"}>{item?.minAmount}$</span>
                        </StyledLimitBlock>
                        <StyledLimitBlock>
                            <span className={"title-limit"}>{t("bonusRules.getUpLimitTitle")}</span>
                            <span className={"title-value"}>{item?.maxAmount}$</span>
                        </StyledLimitBlock>
                    </StyledBonusRulesBlockBlock>
                ))}
            </StyledBonusRulesBlockList>
            <StyledTextBlock padding={"0"} className={"rules-text-block"}>
                <p>{t("bonusRules.descriptionRulesPartOne")}</p>
                <p>{t("bonusRules.descriptionRulesPartTwo")} <span className={"link"}
                                                                   onClick={() => {
                                                                       setVisible(false)
                                                                       setBonusVisible(true)
                                                                   }}>{t("bonusRules.linkInDescriptionPartTwo")}</span>.
                </p>
                <p>{t("bonusRules.descriptionRulesPartThree")} <span className={"link"}
                                                                     onClick={() => {
                                                                         setVisible(false)
                                                                         setBonusVisible(true)
                                                                     }}>{t("bonusRules.linkInDescriptionPartThree")}</span>!
                </p>
            </StyledTextBlock>
            <StyledButton
                color="neutral"
                width="100"
                onClick={() => {
                    setVisible(false)
                    setBonusVisible(true)
                }}
                style={{zIndex: 2, position: "relative"}}
            >
                {t("bonusRules.button")}!
            </StyledButton></>}
        </StyledBonusRulesDialog>
    );
}

export default BonusRulesModal;