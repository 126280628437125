import React from "react";
import { generatePath } from "../../../utils/getLanguage";

export const tagRegex = /bet:(\d+(?:,\d+)*)(?!\w|:)/gm;
// /(?<!\w|:)bet:(\d{1,30}(?!\w|:)+)/gm;

export const BetTag = ({ id, hasIcon = true }) => {
  const cleanedId = id.replace(/,/g, '');
  const classList = ['tag bet-tag'];
  if (hasIcon) {
    classList.push('has-icon');
  }
  return <a href={generatePath(`/bet/${cleanedId}`)} className={classList.join(' ')}>
    {id}
  </a>
}

export default BetTag;
