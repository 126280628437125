import {useEffect} from "react";
import telegramAuthenticationConfig from "../../utils/telegramAuthenticationConfig";

import {StyledSocialButtonWrapper, StyledTelegramButton} from "./styledLogin";

import telegramIcon from "../../assets/images/telegram-button.svg";

const LoginTelegramForm = ({authData, setAuthData}) => {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://telegram.org/js/telegram-widget.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const telegramBotName = () => {
        if (window.location.host === process.env.REACT_APP_BASE_URL_IO) {
            return process.env.REACT_APP_TELEGRAM_BOT_TOKEN_IO;
        }

        return process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
    };

    const handleTelegramResponse = (response) => {
        setAuthData({
            ...authData,
            headers: telegramAuthenticationConfig(response)
        });
    };

    const handleCustomTelegramButton = () => {
        window.Telegram.Login.auth(
            {bot_id: telegramBotName(), request_access: true},
            (data) => {
                if (!data) return;
                handleTelegramResponse(data)
            }
        );
    }



    return (
        <StyledSocialButtonWrapper>
            <StyledTelegramButton onClick={handleCustomTelegramButton}>
                <img src={telegramIcon} alt={"telegram icon"}/>
            </StyledTelegramButton>
        </StyledSocialButtonWrapper>
    );
};

export default LoginTelegramForm;