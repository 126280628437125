import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../App";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import { closableNotification } from "../../../elements/notification/ClosableNotification";
import { responseStatus } from "../../../../utils/consts";
import { parseAxiosError } from "../../../../utils/response";
import AlertMessage from "../../../elements/alert/Alert";
import InputGroup from "../../../elements/inputGroup/InputGroup";
import { StyledButton } from "../../../styles/styledButton";
import LoadButton from "../../../elements/spinner/ButtonSpinner";
import { StyledClientsChangePassword } from "../../styledAccount";
import http from "../../../../http";
import ReCaptcha from "../../../elements/reCaptcha/ReCaptcha";


const ChangePasswordContainer = () => {
  const { user } = useContext(AppContext);

  const [firstLogin, setFirstLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [{ oldPassword, newPassword, confirmNewPassword }, setPasswordInfo] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  });

  const { t } = useTranslation("siteOptions");
  const recaptchaRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const captchaToken = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();

    http.put("/api/users/change-password/" + user.userId, {
      oldPassword: event.target.oldPassword.value.trim(),
      newPassword: event.target.newPassword.value.trim(),
      confirmNewPassword: event.target.confirmNewPassword.value.trim(),
      captchaToken
    }, userAuthenticationConfig(false)).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setErrors({});
        setPasswordInfo({ oldPassword: "", newPassword: "", confirmNewPassword: "" });
        closableNotification(response.data.success, "success");
      }
      setLoading(false);
    }).catch(error => {
      setErrors(parseAxiosError(error.response));
      setLoading(false);
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        setErrors((prevState) => ({
          ...prevState,
          [Object.keys(error.response.data)]: Object.values(error.response.data)
        }));
        return null;
      } else {
        closableNotification(error.response.data.error, "error");
      }
    });
  };

  const checkFirstLogin = () => {
    if (localStorage.getItem("first_login") && localStorage.getItem("first_login") !== "undefined" && localStorage.getItem("first_login" + user.userId) === "first_login") {
      setFirstLogin(true);
    }
  };

  const onChangeInput = (event) => {
    const { name, value } = event.target;
    setPasswordInfo((prevState) => ({ ...prevState, [name]: value.trim() }));
  };

  useEffect(() => {
    checkFirstLogin();
  }, []);

  return (
    <StyledClientsChangePassword>
      {firstLogin &&
        <AlertMessage type="info" message={t('forSecurityReasonsPassword')} mb="30"/>}
      <form onSubmit={handleSubmit} className="form-change-password">
        <InputGroup
          id="oldPassword"
          label={t('yourPassword')}
          type="password"
          name="oldPassword"
          value={oldPassword}
          onChange={onChangeInput}
          error={errors?.oldPassword}
          required
        />
        <InputGroup
          id="newPassword"
          label={t('newPassword')}
          type="password"
          name="newPassword"
          value={newPassword}
          onChange={onChangeInput}
          error={errors?.newPassword}
          required
        />
        <InputGroup
          id="confirmNewPassword"
          label={t('confirmPassword')}
          type="password"
          name="confirmNewPassword"
          value={confirmNewPassword}
          onChange={onChangeInput}
          error={errors?.confirmNewPassword}
          required
        />
        <AlertMessage
          type="warning"
          message={t('makeSureYourPassword')}
          mt="5"
        />
        <ReCaptcha recaptchaRef={recaptchaRef}/>
        <div className="form-change-password__action">
          {!loading ?
            <StyledButton
              color="main"
              type="submit"
              width="100"
            >
              {t('change')}
            </StyledButton> :
            <LoadButton color="info" text={t('change')}/>}
        </div>
      </form>
    </StyledClientsChangePassword>
  );
};

export default ChangePasswordContainer;