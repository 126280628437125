import React from "react";
import { css } from "@emotion/react";
import { MoonLoader, PuffLoader } from "react-spinners";
import { useTheme } from "styled-components";

const Spinner = ({ size, color, display, margin, type }) => {

  const override = css`
    display: ${display};
    margin: ${margin || "15px auto"};
  `;

  const theme = useTheme();

  if (!color) {
    color = theme.text;
  }

  switch (type) {
    case 'puffLoader' :
      return <PuffLoader css={override} size={size} color={color} loading={true} />
    default :
      return <MoonLoader css={override} size={size} color={color} loading={true}/>
  }
};

export default Spinner;