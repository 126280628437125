import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Tabs, { TabPane } from "rc-tabs";
import DiceBetsHistoryContainer from "./DiceBetsHistoryContainer";

import { StyledDiceHistoryWrapper } from "./styledDice";
import { StyledContentWrapper } from "../../styles/styledContainer";
import { AppContext } from "../../../App";
import SendTipWindow from "../../profileActionsWindow/SendTipWindow";
import ProfileActionsWindow from "../../profileActionsWindow/ProfileActionsWindow";
import ReactHintFactory from "react-hint";
import "react-hint/css/index.css";
import { useBetween } from "use-between";
import ProfileActionsStates from "../../profileActionsWindow/ProfileActionsStates";
import DiceStates from "./DiceStates";

const ReactHint = ReactHintFactory(React);

const DiceBetsHistoryWrapper = React.memo(() => {
  const { t } = useTranslation("games");

  const [defaultKeyForBets, setDefaultKeyForBets] = useState(localStorage.getItem("keyForHistoryTab") ?? "allBets");

  const { authenticated, user } = useContext(AppContext);
  const { responseData, setResponseData } = useBetween(DiceStates);

  const {
    handleProfileActionsVisible
  } = useBetween(ProfileActionsStates);

  const changeTab = useCallback((key) => {
    setResponseData((prevState) => ({...prevState, betInfo: null}));
    localStorage.setItem("keyForHistoryTab", key);
    setDefaultKeyForBets(key);
    handleProfileActionsVisible(null, 'dice')
  }, [handleProfileActionsVisible]);

  const [activeNickname, setActiveNickname] = useState(null);
  const [room, setRoom] = useState(null);

  const renderHint = useCallback((target) => {
    const { id, nickname, room } = target.dataset;
    setActiveNickname(nickname);
    setRoom(room);

    return <ProfileActionsWindow
      placement="dice"
      keys={+id}
      nickname={nickname}
    />
  }, []);

  return (
    <StyledDiceHistoryWrapper>
      <ReactHint
        persist
        attribute="data-custom"
        className="custom-hint"
        onRenderContent={renderHint}
        events={{ click: true }}
      />
      {
        authenticated && <SendTipWindow activeNickname={activeNickname} room={room}/>
      }
      <StyledContentWrapper>
        <Tabs
          defaultActiveKey={defaultKeyForBets}
          tabPosition="top"
          className="default-tabs default-tabs-top default-tabs-top_centered"
          onChange={changeTab}
          destroyInactiveTabPane={true}
        >
          {authenticated &&
            <TabPane tab={t("myBets")} key="myBets">
              <DiceBetsHistoryContainer
                type="My"
                authenticated={authenticated}
                user={user}
                responseData={responseData}
                setResponseData={setResponseData}
              />
            </TabPane>}
          <TabPane tab={t("allBets")} key="allBets">
            <DiceBetsHistoryContainer
              type="All"
              authenticated={authenticated}
              user={user}
              responseData={responseData}
              setResponseData={setResponseData}
            />
          </TabPane>
        </Tabs>
      </StyledContentWrapper>
    </StyledDiceHistoryWrapper>
  );
});

export default DiceBetsHistoryWrapper;
