import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import { responseStatus } from "../../../utils/consts";
import { closableNotification } from "../notification/ClosableNotification";
import { StyledPaymentMethodItem, StyledWrapperPaymentItem } from "../../profileActionsWindow/styledProfileActions";
import { StyledPaymentMethodsIcons } from "../../styles/StyledPaymentMethodsIcons";
import InputGroup from "../inputGroup/InputGroup";
import checkObjectIsNullish from "../../../utils/checkObjectIsNullish";
import { StyledButton } from "../../styles/styledButton";
import { StyledLabel } from "../inputGroup/styledInputGroup";
import BetAction from "../betAction/BetAction";
import ReCaptcha from "../reCaptcha/ReCaptcha";
import AlertMessage from "../alert/Alert";
import {AppContext} from "../../../App";
import {useBetween} from "use-between";
import BalanceStates from "../../games/BalanceStates";

const Rain = ({ room, setVisible }) => {

  const [paymentMethods, setPaymentMethods] = useState([]);

  const [activePaymentInfo, setActivePaymentInfo] = useState();

  const [valueNumber, setValueNumber] = useState("0.00000000");

  const [submitData, setSubmitData] = useState({
    currencyAsset: null,
    amount: valueNumber.toString(),
    room: room?.id
  });

  const [codeActive, setCodeActive] = useState("");

  const { t } = useTranslation("siteOptions");
  const recaptchaRef = useRef(null);
  const { twoFaIsActive } = useContext(AppContext);
  const { balance} = useBetween(BalanceStates);

  const handleInput = (valueNumber) => {
    const filteredValue = valueNumber.replace(/[^0-9.]/g, '');

    const value = filteredValue.replace(/,/g, '.');

    if (!isNaN(value) && (value.match(/\./g) || []).length <= 1) {
      let [intPart, decimalPart] = value.split('.');
      if (decimalPart && decimalPart.length > 8) {
        decimalPart = decimalPart.substring(0, 8);
      }
      setValueNumber(decimalPart !== undefined ? [intPart, decimalPart].join('.') : intPart);
    } else {
      setValueNumber(value);
    }
  }

  const trimToEightDecimals = (num) => {
    const str = num.toString();
    const decimalIndex = str.indexOf('.');
    if (decimalIndex === -1) return str;
    return parseFloat(str.slice(0, decimalIndex + 9));
  };

  const formatErrorMessage = (errorMessage) => {
    const regex = /Minimum (\d+\.\d+), Maximum (\d+\.\d+)/;
    const match = errorMessage.match(regex);

    if (!match) {
      return errorMessage;
    }

    const min = parseFloat(match[1]);
    const max = parseFloat(match[2]);

    if (!activePaymentInfo || paymentMethods) return t('errorLimitCurrency')

    const newActivePaymentInfo = {
      ...activePaymentInfo,
      minTip: trimToEightDecimals(min),
      maxTip: trimToEightDecimals(max)
    }

    localStorage.setItem("paymentMethod", JSON.stringify(newActivePaymentInfo));
    setActivePaymentInfo(newActivePaymentInfo)
    setPaymentMethods(prevMethods => {
      return prevMethods.map(method =>
          method.id === activePaymentInfo.id
              ? { ...method, minTip: newActivePaymentInfo.minTip, maxTip: newActivePaymentInfo.maxTip } : method);
    });

    return t('errorLimitCurrency');
  };

  const fetchPaymentMethod = () => {
    axios.get("/api/payment-methods", userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setPaymentMethods(response.data["hydra:member"]);

        setSubmitData((prevState) => ({
          ...prevState,
          currencyAsset: response.data["hydra:member"][0]?.currency.asset
        }));

      }
    }).catch(error => {
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error.response.data.error, "error");
      }
    });
  };

  const createRain = (event) => {
    event.preventDefault();

    const captchaToken = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();

    if (checkObjectIsNullish(submitData)) {
      closableNotification(t("fillAll"), "error");
      return;
    }

    axios.post("/api/rain", { ...submitData, captchaToken, code: codeActive ?? null }, userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_CREATED) {
        setVisible(false);
        closableNotification(t("rainSuccessfully"), "success");
      }
    }).catch(error => {
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(formatErrorMessage(error.response.data.error), "error");
      }
    });
  };

  const handleItemClick = (value) => {
    localStorage.setItem("rain-paymentMethod", JSON.stringify(value.id));
    setActivePaymentInfo(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPaymentMethod();
  }, []);

  useEffect(() => {
    const savedMethod = (localStorage.getItem("rain-paymentMethod") && localStorage.getItem("rain-paymentMethod") !== "undefined") ?
      JSON.parse(localStorage.getItem("rain-paymentMethod")) :
      null;
    let paymentMethod = savedMethod ? paymentMethods.find(method => method.id === savedMethod) : null;
    if (!paymentMethod) {
      const defaultMethod = (localStorage.getItem("paymentMethod") && localStorage.getItem("paymentMethod") !== "undefined") ?
        JSON.parse(localStorage.getItem("paymentMethod")) :
        null;
      paymentMethod = defaultMethod ?? paymentMethods[0];
    }
    setActivePaymentInfo(paymentMethod);
  }, [paymentMethods]);

  useEffect(() => {
    setSubmitData(prevState => ({
      ...prevState,
      currencyAsset: activePaymentInfo?.currency.asset,
      amount: valueNumber.toString()
    }));
  }, [activePaymentInfo, valueNumber]);

  const activeCurrency = useMemo(() => {
    return activePaymentInfo?.currency.asset ?? null;
  }, [activePaymentInfo]);

  return (
    <>
      <div>
        <StyledLabel>{t("chooseTheCoin")}:</StyledLabel>
        <StyledWrapperPaymentItem>
          {paymentMethods.length !== 0 ? paymentMethods.map((value, key) => (
            <StyledPaymentMethodItem
              key={key}
              onClick={() => handleItemClick(value)}
              active={submitData.currencyAsset === value.currency.asset}
              className={`payment-method-item ${activeCurrency === value.currency.asset ? "active" : ""}`}
            >
              <StyledPaymentMethodsIcons className={`payment-method-${value.currency.asset}`} />
            </StyledPaymentMethodItem>
          )) : <StyledPaymentMethodItem>{t("loading")}</StyledPaymentMethodItem>}
        </StyledWrapperPaymentItem>
        <div>
          <InputGroup
            autocomplete="off"
            id="amount"
            type="text"
            label={t("enterSum")}
            name="amount"
            value={valueNumber}
            betAction={
              <BetAction value={valueNumber}
                         setValue={setValueNumber}
                         min={activePaymentInfo?.minTip.toFixed(8) || 0}
                         max={parseFloat(activePaymentInfo?.balances?.amount ?? 0) < activePaymentInfo?.maxTip ? parseFloat(activePaymentInfo?.balances?.amount ?? 0) : activePaymentInfo?.maxTip}
              />}
            hintsWithMax={activePaymentInfo ? {
              ...activePaymentInfo,
              fee: {
                ...activePaymentInfo?.fee,
                min: activePaymentInfo?.minTip.toFixed(8),
                max: activePaymentInfo?.maxTip.toFixed(8)
              }
            } : null}
            handleInput={handleInput}
            required
          />
          {twoFaIsActive ? <InputGroup
              id="twoFaCode"
              label={t("inputKey")}
              name="twoFaCode"
              value={codeActive}
              handleChange={(event) => setCodeActive(event.target.value)}
              type="text"
              maxLength="6"
              placeholder={t("inputKeyP")}
              autocomplete="off"
          /> : null}
          <ReCaptcha recaptchaRef={recaptchaRef} />
          <AlertMessage
              type="warning"
              message={t("rainWarning")}
              mt="20"
              mb="20"
          />
          <StyledButton color="neutral" width="100" onClick={createRain}>
            {t("confirmRain")}
          </StyledButton>
        </div>
      </div>
    </>
  );
};

export default Rain;