import { useState } from "react";

export const VisibleLeftSidebarStates = () => {
  const [visible, setVisible] = useState(false);
  const [menu, setMenu] = useState("");
  const [message, setMessage] = useState("");
  const [focus, setFocus] = useState(false);
  const [tokenBonusVisible, setTokenBonusVisible] = useState(false);
  const [gameBonusVisible, setGameBonusVisible] = useState(false);
  const [rulesBonusVisible, setRulesBonusVisible] = useState(false);

  return {
    visible,
    setVisible,
    menu,
    setMenu,
    message,
    setMessage,
    focus,
    setFocus,
    tokenBonusVisible,
    setTokenBonusVisible,
    gameBonusVisible,
    setGameBonusVisible,
    rulesBonusVisible,
    setRulesBonusVisible
  };
}