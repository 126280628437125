import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import InputGroup from "../inputGroup/InputGroup";
import LoadButton from "../spinner/ButtonSpinner";

import { StyledButton } from "../../styles/styledButton";
import { StyledTwoFaForm } from "../twoFa/styledTwoFa";

const Nickname = ({ handleSubmit, loading }) => {
  const { t } = useTranslation("siteOptions");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("nickname").focus();
  }, []);

  return (
    <StyledTwoFaForm onSubmit={handleSubmit}>
      <InputGroup
        autocomplete="off"
        id="nickname"
        type="text"
        label={t('enterNickname')}
        name="nickname"
        className="nickname"
        required
      />
      <div className="confirm-action">
        {!loading ?
          <StyledButton color="neutral" type="submit">
            {t('confirm')}
          </StyledButton> :
          <LoadButton
            text={t('confirm')}
            color="neutral"
          />
        }
      </div>
    </StyledTwoFaForm>
  );
};

export default Nickname;