import styled from "styled-components";
export const PartnersTableWrapper = styled.div`
  @media screen and (max-width: 803px) {
      & {
          overflow-x: scroll;
      }
  }
`

export const ReferralsTableWrapper = styled.div`
  @media screen and (max-width: 1040px) {
      & {
          overflow-x: scroll;
      }
  }
`

export const StyledScrollTable = styled.div`
  overflow: auto;
  overflow-scrolling: touch;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
    background-color: ${({ theme }) => theme.body};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    height: 8px;
    border: 2px solid ${({ theme }) => theme.body};
    background-color: ${({ theme }) => theme.neutralColor};
    border-radius: 6px;
  }

  &.jackpot {
    background: ${({ theme }) => theme.lightTableBackground};
    width: calc(100% + 40px);
    margin-left: -20px;
  }
`;

export const StyledScrollTablePartners = styled.div`
  overflow-scrolling: touch;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
    background-color: ${({ theme }) => theme.body};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    height: 8px;
    border: 2px solid ${({ theme }) => theme.body};
    background-color: ${({ theme }) => theme.neutralColor};
    border-radius: 6px;
  }

  &.jackpot {
    background: ${({ theme }) => theme.lightTableBackground};
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  .partners-balance-table {
    min-width: min-content !important;
  }
`;

export const StyledTable = styled.div`
  ${({ size }) => size && `font-size: ${size}px`};
  ${({ width }) => width && `min-width: ${width}px`};
  ${({ width }) => width && "width: 100%"};
  ${({ width }) => width && "overflow-x: hidden;"};

  .partners-balance-table__head {
    padding: 0;
    grid-template-columns: minmax(279.07px, 1fr) minmax(133.08px, 165px) minmax(193.54px, 165px) minmax(173.09px, 165px) minmax(221.23px, 165px) !important;
    grid-gap: 0;
  }

  .referrals-balance-table__head {
    padding: 0;
    grid-template-columns: minmax(172.08px, 1fr) minmax(204.21px, 165px) minmax(99.49px, 165px) minmax(93.03px, 165px) minmax(100.16px, 165px) minmax(168.81px, 165px) minmax(162.22px, 165px) !important;
    grid-gap: 0;
    .partners-balance-col__head {
      padding: 0 15px 8px 15px;
    }
  }

  .partners-balance-col__head::before {
    display: none;
  }
  
  .partners-balance-table__row {
    border-bottom: none !important;
    grid-template-columns: minmax(279.07px, 1fr) minmax(133.08px, 165px) minmax(193.54px, 165px) minmax(173.09px, 165px) minmax(221.23px, 165px) !important;
    grid-gap: 0;
  }

  .referrals-balance-table__row {
    padding: 0;
    grid-template-columns: minmax(172.08px, 1fr) minmax(204.21px, 165px) minmax(99.49px, 165px) minmax(93.03px, 165px) minmax(100.16px, 165px) minmax(168.81px, 165px) minmax(162.22px, 165px) !important;
    grid-gap: 0;
    border-bottom: none !important;
  }
  
  @media screen and (max-width: 1040px) {
    .partners-balance-table__head {
      grid-template-columns: minmax(150px, 279.07px) minmax(113.118px, 165px) minmax(164.509px, 165px) minmax(147.12px, 165px) minmax(188.23px, 165px) !important;
    }
    .partners-balance-table__row {
      grid-template-columns: minmax(150px, 279.07px) minmax(113.118px, 165px) minmax(164.509px, 165px) minmax(147.12px, 165px) minmax(188.23px, 165px) !important;
    }
  }

  @media screen and (max-width: 1040px) {
    .partners-balance-table__head {
      grid-template-columns: minmax(150px, 279.07px) minmax(113.118px, 165px) minmax(164.509px, 165px) minmax(147.12px, 165px) minmax(188.23px, 165px) !important;
    }
    .partners-balance-table__row {
      grid-template-columns: minmax(150px, 279.07px) minmax(113.118px, 165px) minmax(164.509px, 165px) minmax(147.12px, 165px) minmax(188.23px, 165px) !important;
    }
  }
  
`;

export const StyledTableHead = styled.div`
  padding: 10px 15px;
  color: #6b99b6;
  font-weight: 500;
  text-transform: uppercase;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(${({ col }) => col}, 1fr);
  grid-column-gap: 15px;

  &.partners-referrals-table__head {
    align-items: center;
    grid-template-columns: 160px 200px repeat(3, 85px) repeat(2, 1fr);
  }
  
  .partners-balance-col__head {
    padding: 0 15px 6.8px 15px;
  }
  .partners-balance-col__head:first-child {
    font-size: 15px;
  }

  @media screen and (max-width: 992px) {
    display: ${({ scroll }) => scroll === "auto" ? "grid" : "none"};
  }
`;

export const StyledColHead = styled.div`
  ${({ position }) => position && `text-align: ${position}`};
`;

export const StyledTableBody = styled.div``;

export const StyledTableRow = styled.div`
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  color: ${({ theme }) => theme.tableRow.color};
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(${({ col }) => col}, 1fr);
  grid-column-gap: 15px;

  &.referrals {
    grid-template-columns: 160px 200px repeat(3, 85px) repeat(2, 1fr);
  }

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.tableRow.borderColor};
  }

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.tableRow.backgroundColor};
  }

  .partners-balance-table__col {
    color: #777777;
    padding: 10px 15px;
    vertical-align: middle;
    text-align: center;
    font-size: 15px;
    line-height: 25px;
    
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      text-align: left;
      height: 15px;
    }
  }
  
  .partners-balance-table__col:first-child {
    padding: 10px 15px;
  }
  
  .referrals-balance-table__col {
    padding: 8px 15px !important;
    vertical-align: middle;
    text-align: center;
    font-size: 15px;
    line-height: 23px;
    color: #777777;
  }
  
  .user-tag {
    color: #9f9f9f;
  }

  ${({ cursor }) => cursor && `
    cursor: ${cursor};
    &:hover {
      background: rgba(236, 238, 238, 0.5) !important;
    }
  `};

  @media screen and (max-width: 992px) {
    grid-template-rows: ${({ scroll }) => scroll === "auto"
            ? "1fr"
            : `repeat(${({ col }) => col}, minmax(50px, auto))`};
    grid-template-columns: ${({ scroll }) => scroll === "auto" ? `repeat(${({ col }) => col}, 150px)` : "1fr"};
    ${({ scroll }) => scroll === "auto" ? `
       & > div {
         &:before {display: none};
        }
    ` : `
      margin-bottom: 20px;
      padding: 15px 20px;
      position: relative;
      border-bottom: 1px solid #F1F1F1;
      &:nth-child(2n) {
        background-color: #F7F8F8;
      }
      & > div {
        padding: 0 0 0 50%;
        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
      .table-actions {
        padding: 0;
        position: absolute;
        top: 7px;
        right: 7px;
        z-index: 1;
        &:before {
          display: none !important;
        }
      }
    `};
  }

  @media screen and (max-width: 576px) {
    ${({ scroll }) => scroll === "auto" ? `
    ` : `
      margin-bottom: 15px;
      padding: 12px 10px;
      & > div {
        padding: 25px 0 0 0;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    `};
  }
`;

export const StyledCol = styled.div`
  display: ${({ inline }) => inline ? "inline-flex" : "grid"};
  align-items: center;
  ${({ position }) => position && `text-align: ${position}`};
  position: relative;
  padding: ${({ customPadding }) => customPadding ?? "10px 7px 14px"};

  &:first-child {
    padding-left: 20px;
  }

  &:last-child {
    padding-right: 20px;
  }

  .wrapper-link {
    cursor: pointer;
    border-bottom: 1px ${({ theme }) => theme.mainTextColor} dashed;
    transition: all 0.3s ease-out;

    &:hover {
      color: #ffffff;
      border-bottom: 1px #ffffff dashed;
    }
    
    img {
      height: 11px;
      margin-right: 5px;
    }
  }
  &.withdraw {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .withdraw-btn {
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0 !important;
    height: 25px;
    padding: 0;
    
    img {
      margin-right: 9px;
      flex-shrink: 0;
      height: 17px;
      opacity: 0.3;
    }
    
    span {
      font-size: 13px;
      color: #fff;
      opacity: 0.3;
      vertical-align: middle !important;
      padding: 0 !important;
    }
    .disabled {
      opacity: 0.6;
    }
  }

  &:before {
    content: attr(data-title) ':';
    color: ${({ theme }) => theme.tableRow.color};
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.4px;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    @media screen and (max-width: 992px) {
      display: block;
    }
  }
`;
