import styled from "styled-components";
import { device } from "./responsive/responsiveUtils";

export const StyledHistoryDate = styled.div`
  padding-bottom: 7px;
  font-size: 12px;
  display: grid;
`;

export const StyledHistoryLink = styled.div`
  position: relative;
  max-width: max-content;
  color: ${({ theme }) => theme.historyLink};
  font-size: ${({ size }) => size ? `${size}px` : "16px"};
  font-weight: ${({ bold }) => bold || "400"};
  overflow: hidden;
  display: inline-block;
  border-bottom: 1px dashed ${({ bordercolor, theme }) => bordercolor ? theme.text : theme.historyLink};
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
    
  .bet-details {
    font-weight: bold;
    line-height: 25px;  
  }  

  &:hover {
    color: ${({ theme }) => theme.historyLinkHover};
    border-color: ${({ theme }) => theme.historyLinkHover};
  }

  @media screen and ${device('desktop')} {
    font-size: 12px;
  }
`;

export const StyledHistoryBet = styled.div`
  color: ${({losing, theme}) => losing ? theme.diceHistory.win : theme.diceHistory.lose};
  text-align: right;
  text-wrap: nowrap;
  
  span:first-child {
    padding-bottom: 7px;
    color: ${({ theme }) => theme.tableRow.color};
    font-size: 12px;
    display: block;
  }
`;