import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useBetween } from "use-between";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ProfileActionsStates from "./ProfileActionsStates";
import { VisibleLeftSidebarStates } from "../elements/leftSidebar/VisibleLeftSidebarStates";
import { generatePath } from "../../utils/getLanguage";
import { LeftSidebarMenu } from "../../utils/consts";
import { StyledWrapper } from "./styledProfileActions";
import { AppContext } from "../../App";
import { closableNotification } from "../elements/notification/ClosableNotification";
import BurgerStates from "../elements/mobileNavigation/BurgerStates";

const ProfileActionsWindow = ({ placement, keys, nickname, parentRef = null, button = null }) => {
  const history = useHistory();

  const {
    visibleProfileAction,
    setVisibleProfileAction,
    place,
    setVisibleTipWindow,
    setNickname
  } = useBetween(ProfileActionsStates);
  const { setGameBonusVisible } = useBetween(VisibleLeftSidebarStates);

  const [position, setPosition] = useState({ x: 0, y: -50 });
  const [arrowPosition, setArrowPosition] = useState({ y: 70 });

  const { t } = useTranslation('siteOptions');
  const { t: errorsT } = useTranslation('errors');

  const { authenticated } = useContext(AppContext);

  const profileActionsRef = useRef(null);

  const calcPos = useCallback(() => {
    if (button && parentRef?.current && profileActionsRef?.current) {
      const { right, bottom, top, height } = button.getBoundingClientRect();
      const { top: parentTop, bottom: parentBottom } = parentRef.current.getBoundingClientRect();

      if (top <= parentTop || bottom >= parentBottom) {
        return;
      }

      const pos = {};
      const maxW = parentRef.current.offsetWidth - profileActionsRef.current.offsetWidth - 40;
      if (right > maxW) {
        pos.x = maxW - right;
      } else {
        pos.x = -10;
      }
      const maxH = parentBottom - profileActionsRef.current.offsetHeight + height;
      if (bottom > maxH) {
        pos.y = maxH - bottom;
      } else if (top < parentTop + 50) {
        pos.y = parentTop - top;
      } else {
        pos.y = -50;
      }
      setPosition(oldPos => ({ ...oldPos, ...pos }));

      let needleArrowPos = (pos.y * -1) + (height / 2);
      needleArrowPos = Math.min(profileActionsRef.current.offsetHeight - height - 10, Math.max(needleArrowPos, 0));
      setArrowPosition({
        y: needleArrowPos
      })
    }
  }, [button, parentRef]);

  const {
    setVisible,
    setMenu,
    setMessage,
    setFocus
  } = useBetween(VisibleLeftSidebarStates);

  const { handleClearAll } = useBetween(BurgerStates);

  const handleMessageClick = useCallback((value = true) => {
    if (authenticated) {
      setVisibleProfileAction(null);
      setMessage(oldMessage => {
        const tag = `user:${nickname}`;
        const message = oldMessage ?? '';
        if (message.includes(tag)) {
          return oldMessage;
        }
        return 'user:' + nickname + ',&nbsp;' + message;
      });
      setFocus(true)
      if (placement === "referrals" || placement === "bonus") {
        setVisible(true)
        setMenu("Chat")
      }
      if (placement === "bonus") setGameBonusVisible(false);
    } else {
      closableNotification(errorsT('Full authentication is required to access this resource.'), 'error');
    }
  }, [authenticated, setVisibleProfileAction, setVisible, setMenu, setMessage, setFocus, errorsT]);

  const handleTipClick = () => {
    setVisibleProfileAction(null);
    setNickname(nickname);
    setVisibleTipWindow(true);
  };

  const arrowStyles = {
    top: arrowPosition.y,
    left: -20
  };

  const posStyles = {
    left: position.x,
    top: position.y
  }

  useEffect(() => {
    calcPos();
    const element = parentRef?.current;
    if (element) {
      element.addEventListener('scroll', calcPos);
    }
    window.addEventListener('resize', calcPos);
    return () => {
      if (element) {
        element.removeEventListener('scroll', calcPos);
      }
      window.removeEventListener('resize', calcPos);
    }
  }, [calcPos, parentRef, profileActionsRef]);

  useEffect(() => {
    calcPos();
    return () => {
      setVisibleProfileAction(false);
    }
  }, []);

  const openUserProfile = () => {
    history.push(`${generatePath(`/account/${nickname}`)}`);
    setVisible(false);
    setMenu("");
    if (placement === "bonus") setGameBonusVisible(false);
    handleClearAll();
  }

  return (
    <>
      <StyledWrapper ref={profileActionsRef} posStyles={posStyles} arrowStyles={arrowStyles}
                     visible={visibleProfileAction === keys && place === placement}
                     isChat={placement === "chat"}
                     isGame={placement === "dice" || placement === "balls" || placement === "roulette"}
                     isRain={placement === "rain"}
                     isTip={placement === "tip"}
                     isBetPage={placement === "betPage"}
                     isContests={placement === "contests"}
                     isReferrals={placement === "referrals"}
                     isBonus={placement === "bonus"}
      >
        <NavLink to={`${generatePath(`/account/${nickname}`)}`} onClick={openUserProfile} onTouchEnd={(e) => e.stopPropagation()}>{t('profile')}</NavLink>
        <NavLink to="#" disabled>{t('addToFriends')}</NavLink>
        <NavLink to="#" onClick={handleMessageClick} onTouchEnd={e => e.stopPropagation()}>{t('message')}</NavLink>
        <NavLink to="#" disabled>{t('privateMessage')}</NavLink>
        <NavLink to="#" onClick={handleTipClick} onTouchEnd={e => e.stopPropagation()}>{t('sendTip')}</NavLink>
        <NavLink to="#" disabled>{t('addToIgnore')}</NavLink>
      </StyledWrapper>
    </>
  );
}

export default ProfileActionsWindow;