import {
  StyledDayItem,
  StyledDayItemAmount,
  StyledDayItemHeader,
  StyledDaysBlock
} from "./styledTokenBonus";
import { useEffect, useState } from "react";
import { StyledSpan } from "../../../styles/styledTypography";

const MAX_TOKEN_DAY = 5;
const MAX_PROGRESS = 100;

const DaysBlock = ({ token, setBonusData, fetchTokenBonusData, t }) => {
  const daysBonus = [
    { dayNumber: 1, amount: "100", currentDay: 1 },
    { dayNumber: 2, amount: "150", currentDay: 2 },
    { dayNumber: 3, amount: "200", currentDay: 3 },
    { dayNumber: 4, amount: "250", currentDay: 4 },
    { dayNumber: 5, amount: "300", currentDay: 5 }
  ];

  const [progressPercentage, setProgressPercentage] = useState(0);

  useEffect(() => {
    const updateProgress = () => {
      const now = new Date();
      const lastOnline = new Date(token.lastOnlineDayDate * 1000);
      const diff = now - lastOnline;
      const diffInMinutes = diff / (1000 * 60);
      const totalMinutesOfDay = 24 * 60;
      const progress = (diffInMinutes / totalMinutesOfDay) * MAX_PROGRESS;

      if (progress >= MAX_PROGRESS) {
        fetchTokenBonusData();
      }

      setProgressPercentage(progress);
    };

    if (token.currentDay !== MAX_TOKEN_DAY) {
      updateProgress();
    }

    const intervalId = setInterval(updateProgress, 60000);

    return () => clearInterval(intervalId);
  }, [token.lastOnlineDayDate, token.currentDay]);

  return (
    <StyledDaysBlock>
      {daysBonus.map(({ dayNumber, amount, currentDay }) => {

        const isDayPassed = currentDay <= token.currentDay;
        const currentDayStyle = currentDay === token.currentDay;
        const prevDayStyle = currentDay < token.currentDay;

        const examinationProgressPercentage = prevDayStyle || token.currentDay === MAX_TOKEN_DAY ? MAX_PROGRESS :  progressPercentage;

        return (
          <StyledDayItem key={dayNumber} hasDay={isDayPassed}>
            <StyledDayItemHeader fillPercentage={examinationProgressPercentage} prevDayStyle={prevDayStyle} currentDayStyle={currentDayStyle}>
              <StyledSpan>
                {t("day", { dayNumber })}
              </StyledSpan>
            </StyledDayItemHeader>
            <StyledDayItemAmount hasDay={isDayPassed}>
              <b>
                {amount}
              </b>
              LUCKY
            </StyledDayItemAmount>
          </StyledDayItem>
        );
      })}
    </StyledDaysBlock>
  );
};

export default DaysBlock;