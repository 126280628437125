import React, { forwardRef, useContext, useLayoutEffect, useMemo } from "react";
import moment from "moment/moment";
import {
  StyledChatMessages,
  StyledMessageAvatar,
  StyledMessageBody,
  StyledMessageHeader,
  StyledMessagesContainer,
  StyledMessageTitle
} from "./styledChatMessages";

import defaultImg from "../../../../../assets/images/no-avatar.svg";
import { decodeMessage } from "../utils";
import ProfileActionsWindow from "../../../../profileActionsWindow/ProfileActionsWindow";
import { useBetween } from "use-between";
import ProfileActionsStates from "../../../../profileActionsWindow/ProfileActionsStates";
import { AppContext } from "../../../../../App";
import { NavLink } from "react-router-dom";
import { generatePath } from "../../../../../utils/getLanguage";
import "react-hint/css/index.css";
import ReactHintFactory from "react-hint";
import { useTranslation } from "react-i18next";
import { renderToStaticMarkup } from "react-dom/server";
import UserTag, { tagRegex as userTagRegex } from "../../../tags/UserTag";
import BetTag, { tagRegex as betTagRegex } from "../../../tags/BetTag";
import CurrencyTag, { tagRegex as currencyTagRegex } from "../../../tags/CurrencyTag";
import {chatEmoji} from "../chatEmoji";
import DOMPurify from "dompurify";

const ReactHint = ReactHintFactory(React);

export default forwardRef(function ({ messages, room }, ref) {
  const { t } = useTranslation("siteOptions");

  const { user } = useContext(AppContext);

  const { handleProfileActionsVisible } = useBetween(ProfileActionsStates);

  const renderHint = (target) => {
    const { id, nickname } = target.dataset;
    return <ProfileActionsWindow
      placement="chat"
      parentRef={ref}
      button={target}
      keys={+id}
      nickname={nickname}
    />
  };

  const avatar = (sender, isSameSender, isCurrentUser) => {
    const src = sender.user.mediaObject ? sender.user.mediaObject.base64 : defaultImg;

    if (!isSameSender) {
      return <StyledMessageAvatar>
        <img src={src} alt={'avatar'}/>
        <div className="rating" title={t('accountRating')}>
          ∞
        </div>
      </StyledMessageAvatar>;
    }
    return null;
  }
  const handleMessageContentClick = (event) => {
    if (event.target.classList.contains('user-tag') && !event.target.classList.contains('current')) {
      handleProfileActionsVisible(+event.target.dataset.id, "chat");
    }
  }
  const userMessage = (sender, key, isSameSender) => {

    const isCurrentUser = user?.nickname === sender.user.nickname;

    return <StyledChatMessages
      key={key}
      className="chat-messages"
      glued={isSameSender}
      align={isSameSender ? (isCurrentUser ? 'start' : 'end') : null}
    >
      {!isCurrentUser ? avatar(sender, isSameSender, false) : null}
      <StyledMessageBody
        className="message-body"
        alignArrow={!isSameSender ? (isCurrentUser ? 'left' : 'right') : null}
      >
        {!isSameSender ? <>
            {
              isCurrentUser ?
                <StyledMessageHeader
                  as={NavLink}
                  to={generatePath(`/account/${sender.user.nickname}`)}
                >
                  {sender.user.nickname}
                </StyledMessageHeader>
                :
                <StyledMessageHeader
                  className="wrapper-relative"
                  onClick={() => handleProfileActionsVisible(key, "chat")}
                  data-custom
                  data-custom-at="right"
                  data-id={key}
                  data-nickname={sender.user.nickname}
                  data-room={room}
                >
                  {sender.user.nickname}
                </StyledMessageHeader>
            }
            <StyledMessageHeader>
              {moment(sender.createdAt * 1000).format("hh:mm")}
            </StyledMessageHeader>
          </>
          : null
        }
        <StyledMessageTitle onClick={handleMessageContentClick}
                            dangerouslySetInnerHTML={{ __html: decodeMessage(sender.value) }}/>
      </StyledMessageBody>
      {isCurrentUser ? avatar(sender, isSameSender, true) : null}
    </StyledChatMessages>
  }

  const botMessage = (sender, key, isSameSender) => {

    const botName = sender.type.split([' '])[1] ?? null;

    return <StyledChatMessages
      key={key}
      className="chat-messages"
      glued={isSameSender}
    >
      <StyledMessageBody className="message-body bot">
        {
          !isSameSender ? <>
            {
              <StyledMessageHeader
                className="wrapper-relative bot"
              >
                <span className={'bot-title'}>BOT</span>
                {botName ? <span className={'bot-name'}>{t(botName)}</span> : null}
              </StyledMessageHeader>
            }
            <StyledMessageHeader>
              {moment(sender.createdAt * 1000).format("hh:mm")}
            </StyledMessageHeader>
          </> : null
        }
        <StyledMessageTitle onClick={handleMessageContentClick}
                            dangerouslySetInnerHTML={{ __html: decodeMessage(sender.value) }}/>
      </StyledMessageBody>
    </StyledChatMessages>
  }

  const stripTagsExceptImages = (sender) => {
    const parser = new DOMParser();
    const messageWrapper = parser.parseFromString(sender.value, 'text/html')?.body;

    if (messageWrapper) {
      for (const child of messageWrapper.children) {
        if (child.tagName.toLowerCase() === 'img') {
          const imageSrc = child.getAttribute('src');
          const currentEmoji = chatEmoji.find((emoji) => emoji.code === imageSrc);
          if (!currentEmoji) {
            child.replaceWith(DOMPurify.sanitize(child.outerHTML));
          }
        } else {
          child.replaceWith(DOMPurify.sanitize(child.outerHTML));
        }
      }
    }

    return messageWrapper.innerHTML;
  };

  const processBeforeOutput = (sender, key) => {
    if (sender.type === "system rules") {
      sender.type = `system ${t("titleBotMessageAboutRules")}`
      sender.value = t("botMessageAboutRules");
    }
    if (typeof sender.value === 'string') {
      sender.value = sender.value.replace(userTagRegex, (substr, username) => {
        const isCurrentUser = user?.nickname === username;
        const hintData = !isCurrentUser ? {
          "data-custom": true,
          "data-custom-at": "right",
          "data-id": window.crypto.getRandomValues(new Uint8Array(10)).join(''),
          "data-nickname": username,
          "data-room": room
        } : {};
        return renderToStaticMarkup(<UserTag username={username} isCurrentUser={isCurrentUser} hintData={hintData}/>);
      });
      sender.value = sender.value.replace(betTagRegex, (substr, id) => renderToStaticMarkup(<BetTag id={id}/>));
      sender.value = sender.value.replace(currencyTagRegex, (substr, coinName) => renderToStaticMarkup(<CurrencyTag coinSymbol={coinName}/>));
    }
    return sender;
  }

  const messagesList = useMemo(() => {
    if (messages) {
      let previousSender = null;

      return messages.map((sender, key) => {
        let message = sender.value;

        if (sender.type === 'user' && typeof sender.value === 'string') {
          message = stripTagsExceptImages(sender);
        }
        let isSameSender = false;

        if (previousSender && previousSender.type === sender.type) {
          isSameSender = sender.type === 'user' ? sender.user.nickname === previousSender.user.nickname : true;
        }

        const newSender = processBeforeOutput({...sender, value: message}, key);
        const result = sender.type === "user" ?
          userMessage(newSender, key, isSameSender) :
          botMessage(newSender, key, isSameSender);

        previousSender = sender;

        return result;
      });
    }
    return null;
  }, [messages, user]);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, []);

  return (
    <StyledMessagesContainer
      className="messages-container"
      ref={ref}
    >
      <ReactHint
        attribute="data-custom"
        className="custom-hint"
        onRenderContent={renderHint}
        events={{ click: true }}
      />
      {messagesList}
    </StyledMessagesContainer>
  );
});
