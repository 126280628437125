import React, {useEffect, useState, useCallback, useMemo, useContext} from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
// import RouletteStates from "./RouletteStates";
import InputGroup from "../../elements/inputGroup/InputGroup";
import { closableNotification } from "../../elements/notification/ClosableNotification";

import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import { responseStatus } from "../../../utils/consts";

import { StyledButton } from "../../styles/styledButton";
import { StyledChip, StyledChipsWrapper, StyledRouletteBetOptions } from "./styledRoulette";
import { useBetween } from "use-between";
// import calculateGameStatistics from "../../../utils/calculateGameStatistics";
import BetAction from "../../elements/betAction/BetAction";
import { CurrentHonestyControl } from "../../elements/leftSidebar/HonestyControl/CurrentHonestyControl";
import { VisibleLeftSidebarStates } from "../../elements/leftSidebar/VisibleLeftSidebarStates";
import BalanceStates from "../BalanceStates";
import AutoBetsContainer from "../../elements/autoBets/AutoBetsContainer";
import { GAMES } from "../Constants";
import { useAnimationSync } from '../AnimationSyncDataStates';
import CurrentStatisticsStates from "../CurrentStatisticStates";
import RouletteCustomValueChipModal from "./RouletteCustomValueChipModal";
import {formatNumber} from "../../../utils/formatNumber";
import Big from "big.js";
import {useHotkeys} from "react-hotkeys-hook";
import {AppContext} from "../../../App";
import * as SessStorage from "../../../utils/sessionStorage";

const RouletteToPlayContainer = ({
                                   submitData,
                                   setSubmitData,
                                   responseData,
                                   setResponseData,
                                   errorData,
                                   setErrorData,
                                   handleChange,
                                   //setIsRotate,
                                   isPlay,
                                   setIsPlay,
                                   isMobile,
                                   autoModeIsStart,
                                   gameInfoRef,
                                   isDisableAutoBet,
                                   animationDisabled,
                                   resetBet,
                                   selectedNumber,
                                   autoModeAcceleration
                                 }) => {
  const { t } = useTranslation("games");

  // const { autoModeIsStart, gameInfoRef, isDisableAutoBet } = useAutoBetsStatesRoulette();
  const { startAnimationSync, stopAnimationSync } = useAnimationSync();

  const chips = {
    "0.00000010": {
      "dignity": "10",
      "color": "red"
    },
    "0.00000100": {
      "dignity": "100",
      "color": "red"
    },
    "0.00001000": {
      "dignity": "1k",
      "color": "red"
    },
    "0.00010000": {
      "dignity": "10k",
      "color": "red"
    },
    "0.01000000": {
      "dignity": "1M",
      "color": "green"
    },
    "0.10000000": {
      "dignity": "10M",
      "color": "green"
    },
    "1.00000000": {
      "dignity": "100M",
      "color": "green"
    },
    "10.00000000": {
      "dignity": "1B",
      "color": "green"
    },
    "100.00000000": {
      "dignity": "10B",
      "color": "green"
    },
    "10000.00000000": {
      "dignity": "1T",
      "color": "yellow"
    },
    "100000.00000000": {
      "dignity": "10T",
      "color": "yellow"
    },
    "1000000.00000000": {
      "dignity": "100T",
      "color": "yellow"
    },
    "10000000.00000000": {
      "dignity": "1Q",
      "color": "yellow"
    }
  };

  const [sum, setSum] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isCustomValueChipModal, setIsCustomValueChipModal] = useState(false);
  const [profitOnWin, setProfitOnWin] = useState("0.00000000");

  const { currentHonestyControl } = useBetween(CurrentHonestyControl);
  const { visible } = useBetween(VisibleLeftSidebarStates);
  const { activeCurrency, setBalance, balance } = useBetween(BalanceStates);
  const { updateStatistics } = useBetween(CurrentStatisticsStates);
  const { paymentMethods, selectedPaymentMethod } = useBetween(BalanceStates);

  const [activePaymentInfo, setActivePaymentInfo] = useState((localStorage.getItem("paymentMethod") && localStorage.getItem("paymentMethod") !== "undefined") ?
      JSON.parse(localStorage.getItem("paymentMethod")) :
      paymentMethods[0]);

  const { hotkeys } = useContext(AppContext);
  const { hotkeysDisabled } = hotkeys;

  const toPlay = useCallback((e) => {
    if (!balance) {
      return  closableNotification(t("topUpYourBalance"), "error");
    }
    console.log('');
    console.log('BET:', submitData.bet.toString());
    console.log('suggestedNumbers:', submitData.suggestedNumbers);
    let data = {
      bet: submitData.bet.toString(),
      clientSeed: currentHonestyControl.clientSeed,
      isActiveStatistic: visible,
      paymentMethod: selectedPaymentMethod?.id,
      serverSeedHash: currentHonestyControl.serverSeedHash,
      suggestedNumbers: submitData.suggestedNumbers
    };

    setIsPlay(true);
    startAnimationSync();

    axios.post("/api/roulettes", data, userAuthenticationConfig(false)).then(response => {
      if (response.status === responseStatus.HTTP_CREATED) {
        if (!gameInfoRef.current?.isLeavePage) {
          console.log(
            'Баланс після ставки:',
            response.data.balance,
            (parseFloat(response.data.lose) !== 0 ?
                `(-${response.data.lose})` :
                `(+${response.data.win})`
            ),
            // `(${response.headers['request-duration']}ms)`
          );
          setBalance(response.data.balance)
          setResponseData({
            ...response.data,
            betInfo: {...JSON.parse(response.data.betInfo), gameObject: {
                max: response.data.max,
                sign: response.data.sign,
                coefficient: response.data.coefficient,
                chanceToWin: response.data.chanceToWin
              }, drawnNumber: response.data.drawnNumber, suggestedNumbers: response.data.suggestedNumbers, resultNumber: response.data.resultNumber},
            bet: submitData.bet.toString(),
            suggestedNumbers: submitData.suggestedNumbers.toString(),
            sign: submitData.sign,
          });
        } else {
          gameInfoRef.current.responseData = { ...response.data, onComplete: false };
        }
        updateStatistics(response.data, 'roulette');
        console.log('post api/roulettes,response.data:', response.data);
      }
    }).catch(error => {
      let terminate = false;
      console.log('error:', error?.response);
      stopAnimationSync();
      if (error?.response?.status) {
        terminate = true;
      }
      if (error?.response?.status === responseStatus.HTTP_UNAUTHORIZED) {
        closableNotification(error?.response?.data?.detail, "error");
      }
      if (error?.response?.status === responseStatus.HTTP_ERROR_VALIDATION) {
        closableNotification(error?.response?.data?.error, "error");
      }
      if (error?.response?.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error?.response?.data?.error || 'Invalid bet', "error");
      }
      if (!gameInfoRef.current?.isLeavePage) {
        setIsPlay(false);
        setErrorData({ error: true, terminate });
      } else {
        gameInfoRef.current.errorData = { error: true, terminate };
      }
    });
  }, [
    updateStatistics,
    setIsPlay,
    currentHonestyControl,
    setResponseData,
    setErrorData,
    submitData,
    visible,
    gameInfoRef,
    stopAnimationSync,
    startAnimationSync,
  ]);

  const sumSuggestedNumbers = (bets) => {
    if (isPlay) return;
    let sum = 0;
    for (let bet of Object.values(bets)) {
      sum += bet;
    }
    return setSum(sum);
  };

  useEffect(() => {
    sumSuggestedNumbers(submitData.suggestedNumbers);
  }, [submitData]);

  const onChange = (e) => {
    const { value } = e.target;
    setSum(value);
    handleChange(e);
  };

  useEffect(() => {
    profitOnWinHandler();
  }, [responseData.win, responseData.lose, isPlay, selectedNumber, submitData.suggestedNumbers]);

  const profitOnWinHandler = useCallback( () => {
    if (!responseData.bet || !responseData.win || responseData.lose > 0 || (!selectedNumber && autoModeAcceleration === 0 && autoModeIsStart) || (!selectedNumber && !autoModeIsStart) || (!submitData.isWiningNumbers && autoModeAcceleration === 0 && autoModeIsStart) || (!submitData.isWiningNumbers && !autoModeIsStart)) return setProfitOnWin("0.00000000");

    const payout = new Big(responseData.bet).plus(new Big(responseData.win));

    setProfitOnWin(formatNumber(((responseData && responseData.drawnNumber) || (autoModeIsStart && autoModeAcceleration > 0)) ? payout.toFixed(8) : "0.00000000"));
  }, [responseData.win, responseData.lose, isPlay, selectedNumber, submitData.suggestedNumbers]);

  const repeatChips = () => {
    if (isPlay || hotkeysDisabled) return;

    let prevBet = SessStorage.getItem("RoulettePrevBet");
    if (prevBet && prevBet !== "undefined") {
      setSubmitData((prevState) => ({ ...prevState, suggestedNumbers: prevBet.suggestedNumbers,  bet: prevBet.bet.toFixed(8).toString() }));
    }
  };

  const cleanChips = () => {
    if (isPlay || hotkeysDisabled) return;

    const {suggestedNumbers} = submitData;
    if (suggestedNumbers) {
      let sum = 0;
      for (let bet of Object.values(suggestedNumbers)) {
        sum += bet;
      }
      if (sum > 0) {
        SessStorage.setItem("RoulettePrevBet", { suggestedNumbers,  bet: sum });
      }
    }

    setSubmitData((prevState) => ({ ...prevState, suggestedNumbers: {}, bet: "0" }));
  };

  const multiplyBet = () => {
    if (isPlay || hotkeysDisabled) return;

    if (new Big(submitData.bet).toFixed(8) >= 0.00000001) {
      let allBet = 0;
      let newObjectBets = {};
      const suggestedNumbers = submitData.suggestedNumbers;
      Object.entries(suggestedNumbers).map((array) => {
        newObjectBets[array[0]] = new Big(array[1]).toFixed(8) * 2;
        allBet += new Big(array[1]).toFixed(8) * 2;
      });

      setSubmitData((prevState) => ({ ...prevState, suggestedNumbers: newObjectBets, bet: allBet.toString() }));
    }
  };

  const divideBet = () => {
    if (isPlay || hotkeysDisabled) return;

    if (new Big(submitData.bet).toFixed(8) > 0.00000001) {
      let allBet = 0;
      let newObjectBets = {};
      const suggestedNumbers = submitData.suggestedNumbers;
      Object.entries(suggestedNumbers).map((array) => {
        newObjectBets[array[0]] = new Big(array[1]).toFixed(8) / 2;
        allBet += new Big(array[1]).toFixed(8) / 2;
      });

      setSubmitData((prevState) => ({ ...prevState, suggestedNumbers: newObjectBets, bet: allBet.toString() }));
    }
  };

  const spinRoulette = () => {
    if (isPlay || hotkeysDisabled) return;

    toPlay();
  };

  useHotkeys('q', repeatChips, { enabled: !hotkeysDisabled }, [hotkeysDisabled, isPlay]);
  useHotkeys('w', cleanChips, { enabled: !hotkeysDisabled }, [hotkeysDisabled, isPlay]);
  useHotkeys('a', multiplyBet, { enabled: !hotkeysDisabled }, [hotkeysDisabled, isPlay, submitData]);
  useHotkeys('s', divideBet, { enabled: !hotkeysDisabled }, [hotkeysDisabled, isPlay, submitData]);
  useHotkeys('space', spinRoulette, { enabled: !hotkeysDisabled, preventDefault: true }, [hotkeysDisabled, isPlay, submitData]);

  return (
      <StyledRouletteBetOptions
          isMobile={isMobile}
      >
        <RouletteCustomValueChipModal isPlay={isPlay} autoModeIsStart={autoModeIsStart} setIsOpen={setIsOpenModal} submitData={submitData}
                                      isOpen={isOpenModal} setSubmitData={setSubmitData} setCustom={setIsCustomValueChipModal}/>
        <div>
          {
              isMobile && (
                  <StyledButton style={{userSelect: "none", pointerEvents: "auto"}} mt="14" color={(isPlay || autoModeIsStart || isDisableAutoBet || responseData.drawnNumber) ? "danger" : "main"} onClick={toPlay} disabled={isPlay || autoModeIsStart || isDisableAutoBet || responseData.drawnNumber || !selectedPaymentMethod} className="control">
                    {t("spin")}
                  </StyledButton>
              )
          }
          <InputGroup
              label={t("bet")}
              type="text"
              name="bet"
              value={Number(sum).toFixed(8)}
              onChange={onChange}
              position="center"
              betAction={
                <BetAction
                    isPlay={isPlay || autoModeIsStart || responseData.drawnNumber || !selectedPaymentMethod}
                    value={sum}
                    handleChange={handleChange}
                    setValue={setSum}
                    max={selectedPaymentMethod?.balances?.amount}
                    min="1"
                    isRoulette={true}
                    submitData={submitData}
                />}
              // error={
              //   sum < 0 ||
              //   sum > submitData.paymentMethod?.balances?.amount}
              readOnly
          />
          <InputGroup
              label={t("wonAmount")}
              type="text"
              value={profitOnWin}
              name="win"
              readOnly
          />
          {
              !isMobile && (
                  <StyledButton style={{userSelect: "none", pointerEvents: "auto"}} mt="14" color={(isPlay || autoModeIsStart || isDisableAutoBet || responseData.drawnNumber) ? "danger" : "main"} onClick={toPlay} disabled={isPlay || autoModeIsStart || isDisableAutoBet || responseData.drawnNumber || !selectedPaymentMethod}>
                    {t("spin")}
                  </StyledButton>
              )
          }
        </div>
        <div>
          {
              isMobile && (
                  <AutoBetsContainer
                      game={GAMES.ROULETTE}
                      toPlay={toPlay}
                      responseData={responseData}
                      submitData={submitData}
                      setSubmitData={setSubmitData}
                      errorData={errorData}
                      setErrorData={setErrorData}
                      setStopAnimation={setIsPlay}
                      isMobile={isMobile}
                      isPlay={isPlay}
                      animationDisabled={animationDisabled}
                      resetBet={resetBet}
                  />
              )
          }
          <StyledChipsWrapper
              isMobile={isMobile}
          >
            <div className="title">
              {t("chips")}:
            </div>
            <div
                className="chips-grid"
            >
              <StyledChip
                  color="blue"
                  onClick={() => {
                    setIsOpenModal(true)
                    setSubmitData((prevState) => ({ ...prevState, "currentBet": "N"}))
                  }}
                  current={isCustomValueChipModal}
                  value="N"
              ><span>N</span>{activeCurrency}</StyledChip>
              {Object.entries(chips).map(([key, value]) => (
                  <StyledChip
                      current={!isCustomValueChipModal && submitData.currentBet === key.toString()}
                      color={value.color}
                      onClick={() => {
                        setSubmitData((prevState) => ({ ...prevState, "currentBet": key.toString() }))
                        setIsCustomValueChipModal(false)
                      }}
                      value={key}
                      key={key}
                  >
                    <span>{value.dignity}</span>
                  </StyledChip>
              ))}
              <StyledChip
                  color="gray"
                  onClick={(e) => {
                    setSubmitData((prevState) => ({ ...prevState, "currentBet": e.target.value }))
                    setIsCustomValueChipModal(false)
                  }}
                  current={!isCustomValueChipModal && submitData.currentBet === "clear"}
                  value="clear"
              >
              </StyledChip>
            </div>
          </StyledChipsWrapper>
          {
              !isMobile && (
                  <AutoBetsContainer
                      game={GAMES.ROULETTE}
                      toPlay={toPlay}
                      responseData={responseData}
                      submitData={submitData}
                      setSubmitData={setSubmitData}
                      errorData={errorData}
                      setErrorData={setErrorData}
                      setStopAnimation={setIsPlay}
                      isMobile={isMobile}
                      isPlay={isPlay}
                      animationDisabled={animationDisabled}
                      resetBet={resetBet}
                  />
              )
          }
        </div>
      </StyledRouletteBetOptions>
  );
};

export default RouletteToPlayContainer;