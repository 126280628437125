import header from "./header.json";
import siteOptions from "./siteOptions.json";
import games from "./games.json";
import leftSidebar from "./leftSidebar.json";
import faq from "./faq.json";
import banners from "./banners.json";
import footer from "./footer.json";
import contests from "./contests.json";
import errors from "./errors.json";
import staking from "./staking.json";
import store from "./store.json";

export const translateRU = Object.assign(
  header,
  games,
  leftSidebar,
  siteOptions,
  faq,
  banners,
  footer,
  contests,
  errors,
  staking,
  store
);