import React from "react";
import ucFirst from "../../../utils/ucFirst";
import { StyledFlexInline, StyledRadioBox, StyledTitle } from "./styledAutoBets";
import InputGroup from "../inputGroup/InputGroup";
import { useTranslation } from "react-i18next";
import { StyledFlexWrapper } from "../leftSidebar/Games/styledLeftSidebarGames";
import CustomSelect from "../inputGroup/CustomSelect";

const AutoBetsByLoseOrWins = ({ autoMode, setAutoMode, direction, game }) => {
  const { t } = useTranslation("games");
  return (
    <StyledRadioBox position={direction === "lose" ? "left" : "right"}>
      <StyledFlexWrapper className={"select-block"}>
        <CustomSelect setValue={(index) => {
            let values = { ...autoMode };
            values[direction]["subsequence"] = index;
            setAutoMode(values);
        }} options={[t("every"), t("streakOf")]} />
        <InputGroup
          type="text"
          id={"countOf" + ucFirst(direction)}
          value={autoMode[direction]["countOfSubsequence"]}
          placeholder="1"
          onChange={(data) => {
            const inputValue = data.target.value;
            let values = { ...autoMode };
            if ((!isNaN(Number(inputValue)) && Number(inputValue) >= 1 && Number(inputValue) <= 9999) || inputValue === '') {
              values[direction]["countOfSubsequence"] = inputValue === '' ? '' : Number(inputValue);
              setAutoMode(values);
            } else {
              setAutoMode(values);
            }
          }}
          paddingInput="5px 8px 7px"
          marginInput="-2px 10px 0 10px"
          width="65px"
          height="33px"
          textAlign="center"
          fontSizeInput="16px"
          autoInput={true}
        />
        <StyledTitle>{direction === "lose" ? t("losses") : t("wins")}</StyledTitle>
      </StyledFlexWrapper>

      <div className="flex-inline">
        <input
          style={{cursor: "pointer"}}
          type="radio"
          id={"returnToBaseFor" + ucFirst(direction)}
          className="radio__input"
          name={"radioChoiceFor" + ucFirst(direction)}
          checked={autoMode[direction]["returnToBase"]}
          value={autoMode[direction]["returnToBase"]}
          onChange={(data) => {
            let values = { ...autoMode };
            values[direction]["returnToBase"] = true;
            values[direction]["decrease"]["enable"] = false;
            values[direction]["increase"]["enable"] = false;
            setAutoMode(values);
          }}
        />
        <span className="custom-radio" style={{cursor: "pointer"}}></span>
        <label htmlFor={"returnToBaseFor" + ucFirst(direction)} style={{cursor: "pointer"}}>{t("returnToBase")}</label>
      </div>

      <StyledFlexInline className={"increase-bet-by"}>
        <div className="flex-inline position-relative">
          <input
            style={{cursor: "pointer"}}
            type="radio"
            id={"increaseByFor" + ucFirst(direction)}
            className="radio__input"
            name={"radioChoiceFor" + ucFirst(direction)}
            checked={autoMode[direction]["increase"]["enable"]}
            value={autoMode[direction]["increase"]["enable"]}
            onChange={(data) => {
              let values = { ...autoMode };
              values[direction]["increase"]["enable"] = true;
              values[direction]["returnToBase"] = false;
              values[direction]["decrease"]["enable"] = false;
              setAutoMode(values);
            }}
          />
          <span className="custom-radio" style={{cursor: "pointer"}}></span>
          <label htmlFor={"increaseByFor" + ucFirst(direction)} style={{cursor: "pointer"}}>{t("increaseBetBy")}</label>
        </div>
        <StyledFlexWrapper>
          <InputGroup
            type="text"
            id={"increaseByValueFor" + ucFirst(direction)}
            placeholder="0"
            value={autoMode[direction]["increase"]["value"]}
            onChange={(data) => {
              const inputValue = data.target.value;
              let values = { ...autoMode };
              const regex = /^\d{0,4}\.{0,1}\d{0,5}$/;
              if ((regex.test(inputValue) && Number(inputValue) <= 9999.99999) || inputValue === undefined || inputValue === '') {
                values[direction]["increase"]["value"] = inputValue;
                setAutoMode(values);
              } else {
                setAutoMode(values);
              }
            }}
            paddingInput="5px 8px 7px"
            marginInput="0 5px 0 0"
            width="65px"
            height="33px"
            textAlign="center"
            fontSizeInput="16px"
            autoInput={true}
          />
          <label className="without-padding" htmlFor={"increaseByValueFor" + ucFirst(direction)}>%</label>
        </StyledFlexWrapper>
      </StyledFlexInline>


      <StyledFlexInline className={"decrease-bet-by"}>
        <div className="flex-inline position-relative">
          <input
            style={{cursor: "pointer"}}
            type="radio"
            id={"decreaseByFor" + ucFirst(direction)}
            className="radio__input"
            name={"radioChoiceFor" + ucFirst(direction)}
            checked={autoMode[direction]["decrease"]["enable"]}
            value={autoMode[direction]["decrease"]["enable"]}
            onChange={(data) => {
              let values = { ...autoMode };
              values[direction]["decrease"]["enable"] = true;
              values[direction]["returnToBase"] = false;
              values[direction]["increase"]["enable"] = false;
              setAutoMode(values);
            }}
          />
          <span className="custom-radio" style={{cursor: "pointer"}}></span>
          <label htmlFor={"decreaseByFor" + ucFirst(direction)} style={{cursor: "pointer"}}>{t("decreaseBetBy")}</label>
        </div>
        <StyledFlexWrapper>
          <InputGroup
            type="text"
            id={"decreaseByValueFor" + ucFirst(direction)}
            placeholder="0"
            value={autoMode[direction]["decrease"]["value"]}
            onChange={(data) => {
              const inputValue = data.target.value;
              let values = { ...autoMode };
              const regex = /^\d{0,2}\.{0,1}\d{0,2}$/;
              if ((regex.test(inputValue) && Number(inputValue) <= 99.99) || inputValue === undefined || inputValue === '') {
                values[direction]["decrease"]["value"] = inputValue;
                setAutoMode(values);
              } else {
                setAutoMode(values);
              }
            }}
            paddingInput="5px 8px 7px"
            marginInput="0 5px 0 0"
            width="65px"
            height="33px"
            textAlign="center"
            fontSizeInput="16px"
            autoInput={true}
          />
          <label className="without-padding" htmlFor={"decreaseByValueFor" + ucFirst(direction)}>%</label>
        </StyledFlexWrapper>
      </StyledFlexInline>


      <div className="flex-inline reverse-direction">
        <input
          type="checkbox"
          id={"swapDirectionOf" + ucFirst(direction)}
          className="checkbox__input"
          name={"radioChoiceFor" + ucFirst(direction)}
          checked={autoMode[direction]["swapDirection"]}
          value={autoMode[direction]["swapDirection"]}
          onChange={(data) => {
            let values = { ...autoMode };
            values[direction]["swapDirection"] = data.currentTarget.checked;
            setAutoMode(values);
          }}
          style={{cursor: "pointer"}}
        />
        <span className="custom-checkbox" style={{cursor: "pointer"}}></span>
        <label htmlFor={"swapDirectionOf" + ucFirst(direction)} style={{cursor: "pointer", userSelect: "none"}}>{t(`reverseDirection.${game}`)}</label>
      </div>

      <div className="flex-inline">
        <input
          type="checkbox"
          id={"stopAutoModeOf" + ucFirst(direction)}
          className="checkbox__input"
          checked={autoMode[direction]["stopAutoMode"]}
          value={autoMode[direction]["stopAutoMode"]}
          name={"radioChoiceFor" + ucFirst(direction)}
          onChange={(data) => {
            let values = { ...autoMode };
            values[direction]["stopAutoMode"] = data.currentTarget.checked;
            setAutoMode(values);
          }}
          style={{cursor: "pointer"}}
        />
        <span className="custom-checkbox" style={{cursor: "pointer"}}></span>
        <label htmlFor={"stopAutoModeOf" + ucFirst(direction)} style={{cursor: "pointer"}}>{t("stopAuto")}</label>
      </div>
    </StyledRadioBox>
  );
};

export default AutoBetsByLoseOrWins;
