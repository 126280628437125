import React, {useCallback, useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink, useHistory} from "react-router-dom";
import PageSpinner from "../../elements/spinner/PageSpinner";
import {TimestampToDateTimeWithSecondsABC} from "../../../utils/timestampToDate";
import {StyledCloseImg, StyledContentWrapper} from "../../styles/styledContainer";
import {StyledMainTitle} from "../../styles/styledTitle";
import {StyledDetailsWrapper} from "../styledDetails";
import {StyledPaymentMethodsIcons} from "../../styles/StyledPaymentMethodsIcons";
import {
    SnapshotButton,
    StyledAccountActivityItem,
    StyledBetsWrapper
} from "../../account/client/details/styledClientDetails";
import clock from "../../../assets/images/account/clock_icon.svg";
import userIcon from "../../../assets/images/no-avatar.svg";
import kubik from "../../../assets/images/account/bets_icon.svg";
import clover from "../../../assets/images/account/clover_icon.svg";
import multiply from "../../../assets/images/account/multiplier_icon.svg";
import percent from "../../../assets/images/account/percent_icon.svg";
import {generatePath} from "../../../utils/getLanguage";
import {AppContext} from "../../../App";
import {useBetween} from "use-between";
import ProfileActionsStates from "../../profileActionsWindow/ProfileActionsStates";
import {StyledHistoryLink} from "../../styles/styledHistory";
import CheckHonestyControl from "../CheckHonestyControl";
import RouletteSnapshotModal from "../../games/roulette/RouletteSnapshotModal";

const BetsRouletteDetails = ({bet}) => {
    const history = useHistory();
    const {t} = useTranslation("siteOptions");

    const [snapshotBetData, setSnapshotBetData] = useState(null);

    const {user} = useContext(AppContext);

    const {
        handleProfileActionsVisible
    } = useBetween(ProfileActionsStates);

    const goBack = useCallback(() => history.goBack(), [history]);
    // const goToLink = useCallback((link) => history.push(generatePath(link)), [history]);

    if (!bet) {
        return <PageSpinner/>
    }

    return (
        <>
            <StyledDetailsWrapper>
                <StyledContentWrapper width="826" isFlex={true} column={true} position="center">
                    <StyledMainTitle className="page-title" position="center" mb="30" mt="1">
                        {t("roulette")} #{new Intl.NumberFormat("en").format(bet.id)}
                        <span className="verified">{t("verified")}</span>
                    </StyledMainTitle>
                    <StyledCloseImg onClick={goBack}/>
                    <StyledBetsWrapper>
                        <StyledAccountActivityItem className="box-1-3">
                            <div className="activity-img">
                                <img src={clock} alt="" width={30}/>
                            </div>
                            <b>{TimestampToDateTimeWithSecondsABC(bet.createdAt)}</b>
                            <span>
              {t("date")}
            </span>
                        </StyledAccountActivityItem>
                        <StyledAccountActivityItem className="box-1-3">
                            <div className="activity-img user-avatar">
                                <img src={userIcon} className="userIcon" alt=""/>
                                <div className="level" title={t("accountRating")}>
                                    ∞
                                </div>
                            </div>
                            <b>{
                                user?.nickname === bet.user.nickname ?
                                    <StyledHistoryLink as={NavLink} to={generatePath(`/account/${bet.user.nickname}`)}>
                                        <div>
                                            {bet.user.nickname}
                                        </div>
                                    </StyledHistoryLink>
                                    :
                                    <StyledHistoryLink
                                        onClick={() => handleProfileActionsVisible(bet.id, "tips")}
                                        data-custom
                                        data-custom-at="right"
                                        data-id={bet.id}
                                        data-nickname={bet.user.nickname}
                                    >
                                        {bet.user.nickname}
                                    </StyledHistoryLink>
                            }</b>
                            <span>
              {t("player")}
            </span>
                        </StyledAccountActivityItem>
                        <StyledAccountActivityItem className="box-1-4">
                            <div className="activity-img">
                                <img src={kubik} alt="" width={30}/>
                            </div>
                            <SnapshotButton onClick={() => setSnapshotBetData(bet)}>
                                <svg height="20" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                    <path
                                        d="M50 100c27 0 50-23 50-50S77 0 50 0 0 23 0 50s23 50 50 50Zm0-8C27 92 8 73 8 50S27 8 50 8a42 42 0 1 1 0 84Zm-9-24 27-15c2-2 1-4 0-5L41 32c-2-1-4 0-4 2v32c0 2 2 3 4 2Z"
                                    />
                                </svg>
                                <span>Watch</span>
                            </SnapshotButton>
                            <span>
              {t("betOne")}
            </span>
                        </StyledAccountActivityItem>
                        <StyledAccountActivityItem className="box-1-4">
                            <div className="activity-img">
                                <img src={clover} alt="" width={30}/>
                            </div>
                            <b>{bet.drawnNumber}</b>
                            <span>
              {t("drawnBall")}
            </span>
                        </StyledAccountActivityItem>
                        <StyledAccountActivityItem className="box-1-3">
                            <div className="activity-img currency">
                                <StyledPaymentMethodsIcons
                                    width="50"
                                    height="50"
                                    className={`payment-method-big-${bet.paymentMethod.currency.asset}`}
                                />
                            </div>
                            <b>{bet.bet}</b>
                            <span>
              {t("betAmount")}
            </span>
          </StyledAccountActivityItem>
          <StyledAccountActivityItem className="box-1-3">
            <div className="activity-img currency">
              <StyledPaymentMethodsIcons
                width="50"
                height="50"
                className={`payment-method-big-${bet.paymentMethod.currency.asset}`}
              />
            </div>
            <b className={bet.win != 0 ? "win" : "lose"}>{parseFloat(bet.lose) === 0 ? (parseFloat(bet.bet) + parseFloat(bet.win)).toFixed(8) : parseFloat("0").toFixed(8)}</b>
            <span>
              {t("payout")}
            </span>
                        </StyledAccountActivityItem>
                        <StyledAccountActivityItem className="box-1-4">
                            <div className="activity-img">
                                <img src={multiply} className="multiply" alt="" width={30}/>
                            </div>
                            <b>{bet.gameObject.coefficient}x</b>
                            <span>
              {t("multiplier")}
            </span>
                        </StyledAccountActivityItem>
                        <StyledAccountActivityItem className="box-1-4">
                            <div className="activity-img">
                                <img src={percent} className="percent" alt="" width={30}/>
                            </div>
                            <b>{bet.gameObject.chanceToWin}%</b>
                            <span>
              {t("chance")}
            </span>
                        </StyledAccountActivityItem>
                    </StyledBetsWrapper>
                    <CheckHonestyControl bet={bet}/>
                </StyledContentWrapper>
            </StyledDetailsWrapper>
            {snapshotBetData &&
                <RouletteSnapshotModal snapshotBetData={snapshotBetData} setSnapshotBetData={setSnapshotBetData}/>}
        </>
    );
};

export default BetsRouletteDetails;
