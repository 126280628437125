import styled, { css } from "styled-components";
import zoom from "../../assets/images/search-icon.svg";
import { device } from "../styles/responsive/responsiveUtils";

export const StyledPaymentMethodWrapper = styled.div`
  display: grid;
  grid-template-columns: 180px 1fr;
  user-select: none;
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

export const StyledPaymentMethodList = styled.div`
  height: 250px;
  width: 165px;
  padding-right: 20px;
  z-index: 2;
  scroll-behavior: smooth;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    border-width: 0 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.elementBg};
    background-color: ${({ theme }) => theme.scrollTrack}C0;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.scrollThumb};
    }

    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.scrollTrack};
    }
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: ${({ theme }) => theme.scrollThumb}C0;

    &:hover {
      background-color: ${({ theme }) => theme.scrollThumbHover};
    }
  }
`;

export const StyledSearchWrapper = styled.div`
  position: absolute;
  padding: 15px 0 8px;
  width: 141px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.elementBg};
  z-index: 1;


  ${({ isNavigation }) => isNavigation && css`
    margin: 0;
  `};

  .coin-search__input {
    border-radius: 3px;
    background-image: url(${zoom});
    background-repeat: no-repeat;
    
    font-family: inherit;
    
    position: relative;
    z-index: 2;
    //transition: all 0.15s ease-in-out;

    ${({ isNavigation }) => isNavigation ?
      css`
        width: 100%;
        padding: 15px 50px;
        margin: 0;
        border: none;
        background-color: ${({ theme }) => theme.paymentMethodSearch.mobileNav.backgroundColor};
        color: ${({ theme }) => theme.paymentMethodSearch.mobileNav.color};
        background-position: 20px 50%;
        background-size: 20px;
        font-size: 15px;
      ` :
      css`
        background-color: transparent;
        width: 92px;
        height: 35px;
        padding: 7px 30px 7px 9px;
        border: 1px solid ${({ theme }) => theme.paymentMethodSearch.borderColor};
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2);
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2);
        opacity: ${({ theme }) => theme.paymentMethodSearch.opacity};
        color: ${({ theme }) => theme.paymentMethodSearch.color};
        background-position: top 10px right 13px;
        background-size: 14px;
        font-size: 14px;
      `};

    &:focus {
      width: 100%;
      border-bottom-color: #058bd8;

      ~ .coin-config__button {
        display: none;
      }
    }

    &.active {
      width: 100%;

      ~ .coin-config__button {
        display: none;
      }
    }
  }

  .coin-search__clean {
    color: #777777;
    width: 30px;
    height: 30px;
    position: absolute;
    
    &:hover {
      color: ${({ theme }) => theme.historyLinkHover};
    }
    
    
    
    font-weight: 500;
    text-align: center;
    line-height: 30px;
    display: none;
    cursor: pointer;
    transition: all 0.15s ease-in-out;

    ${({ isNavigation }) => isNavigation ?
      css`
        right: 15px;
        top: 10px;
        background-color: transparent;
      ` :
      css`
        right: 2px;
        top: 17px;
        background-color: ${({ theme }) => theme.leftSidebar.blockBgColor};
      `};

    &.active {
      display: block;
      z-index: 3;
    }
  }

  .coin-config__button {
    ${({ theme }) => theme.paymentMethodConfig};
    background-size: 18px;
    align-self: center;
    margin: 1px 1px 0 0;
    width: 42px;
    height: 33px;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

    &:hover {
      opacity: 1;
    }
  }
`;

export const StyledPaymentMethodItem = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 9px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  transition: all .1s ease;
  ${({ theme }) => theme.paymentMethod};
  margin-bottom: 3px;

  &:last-child {
    margin-bottom: 20px;
  }

  &:hover {
    ${({ theme }) => theme.paymentMethodHover};
  }

  &.active {
    ${({ theme }) => theme.paymentMethodActive};
  }

  .payment-method-item {
    &__name {
      padding: 2px 0;
        //color: ${({ theme }) => theme.diceHistory.numColor};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media screen and (min-width: 1100px) {
        text-align: left;
        max-width: 95px;
      }
    }
  }

  .nothing-found {
    ${({ theme }) => theme.paymentMethod};
    overflow: unset;
  }
`;

export const StyledNothingFound = styled.div`
  ${({ theme }) => theme.paymentMethodNothingFound};
  padding: 7px 10px;
  font-size: 13px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
`;

export const StyledBalanceWrapper = styled.div`
  width: 380px;
  padding: 22px 30px 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-right: 2px solid ${({ theme }) => theme.scrollTrack};

  .input-group {
    margin-bottom: 0;

    label, input {
      text-align: center;
    }
  }

  .wallet input {
    cursor: pointer;
  }

  .balance-actions {
    display: flex;

    button {
      width: 50%;
      margin-top: 5px;

      &:not(:last-child) {
        margin-right: 19px;
        @media screen and (max-width: 1080px) {
          margin-right: 10px;
        }
      }
    }
  }
`;

export const StyledMenuHeaderItem = styled.div`
  display: flex !important;
  width: unset !important;
  align-items: center;

  &.nav-title {
    font-size: 24px;
    color: ${({ theme }) => theme.text};
    line-height: 24px;
    font-weight: 100;
  }

  .coin-config__button {
    ${({ theme }) => theme.paymentMethodConfigMobile};
    background-size: 20px;
    align-self: center;
    margin: 1px 1px 0 0;
    width: 20px;
    height: 24px;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

    &:hover {
      opacity: 1;
    }
  }

  .close {
    display: block;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
    margin-left: 10px;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }

    &:before, &:after {
      content: "";
      position: absolute;
      width: 24px;
      height: 2px;
      background: ${({ theme }) => theme.mobileNav.closeBtn.backgroundColor};
      top: 50%;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
`;

export const StyledMenuHeader = styled.div`
  display: flex !important;
  justify-content: space-between;
  border-width: 0 0 1px 0;
  border-style: solid;
  ${({ theme }) => theme.leftSidebarChat.header}
  min-height: 65px;
  height: 65px;
  padding: 18px 20px;
`;

export const StyledPaymentMethodListWrapper = styled.div`
  display: flex !important;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: flex-start;
  
  & > .payment-method-item {
    width: 48%;
    margin: 0 1% 8px 1% !important;
    color: ${({theme}) => theme.mobileNav.item.color};
    background-color: ${({theme}) => theme.mobileNav.item.backgroundColor};
    transition: all 0.3s ease;
    flex-direction: row-reverse;
    padding: 8px 15px;
    font-size: 16px;
    font-weight: normal;
    opacity: 1;

    .payment-method-item__name {
      text-align: start;
      padding-left: 5px;
    }

    & > span {
      opacity: 0.6;
      transition: all 0.3s ease;
    }
    
    &.active, &:hover {
      color: ${({theme}) => theme.mobileNav.itemHover.color};
      background-color: ${({theme}) => theme.mobileNav.itemHover.backgroundColor};

      & > span {
        opacity: 1;
      }
    }
    
    @media screen and ${device('xs')}{
      width: 98%;
    }
  }
`;

export const StyledPaymentCoinList = styled.div`
  margin-top: 58px;
`
