import styled from "styled-components";
import { device } from "../../styles/responsive/responsiveUtils";

export const StyledFlexInline = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const StyledSlider = styled.div`
  width: 100%;
  height: 15px;
`

export const StyledSliderBackBar = styled.div`
  background-color: ${({ theme }) => theme.autoBet.slider};
  height: 15px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
`;

export const StyledSliderSelectedBar = styled.div`
  background-image: linear-gradient(to bottom, #60aedc, #0b7ec2);
  background-repeat: repeat-x;
  background-color: #a1fad0;
  height: 15px;
  border-radius: 5px;
  transition: all 0.2s ease-out;

  @media screen and ${device('xs')} {
    height: 10px
  }
  
  width: ${({ value }) => {
    if (value === 0) {
      return '5.7142857142857%';
    }
    if (value === 1) {
      return '33.5238095238095%';
    }
    if (value === 2) {
      return '66.8571428571429%';
    }
    return '100%';
  }}
`;

export const StyledSliderPointer = styled.div`
  position: absolute;
  background-color: #aaaaaa;
  background-image: linear-gradient(to bottom, #eeeeee, #aaaaaa);
  width: 21px;
  height: 21px;
  margin-left: -10.5px;
  margin-top: -17.5px;
  border-radius: 10px;
  transition: all 0.2s ease-out;
  background-repeat: repeat-x;
  border: 1px solid ${({ theme }) => theme.autoBet.point};  

  @media screen and ${device('xs')} {
    margin-top: -15px;
  }
  
  left: ${({ value }) => {
    if (value === 0) {
      return '5.7142857142857%';
    }
    if (value === 1) {
      return '33.5238095238095%';
    }
    if (value === 2) {
      return '66.8571428571429%';
    }
    return '100%';
  }}
`;

export const StyledSliderClickableDummy = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 15px;
  top: 0px;
`;
/*
525
0 - 30 = 0,057142857142857
1 - 176 = 0,335238095238095
2 - 351 = 0,668571428571429
3 - 525 = 1
*/

export const StyledRadioBox = styled.div`
  ${({ position }) => position === "left" ?
    `width: 270px;
           text-align: left;
           min-width: 270px;` :
    position === "right" ?
      `width: 270px;
                min-width: 270px;` : ''};
  background: ${({ theme }) => theme.autoBet.backgroundInfo};
  margin-bottom: 20px;
  padding: 10px 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
    
  & .select-block {
    margin-bottom: 10px;
  }  
    
  & .increase-bet-by {
      padding-bottom: 1.5px;
  }  
    
  & .decrease-bet-by {
      padding-top: 1.5px;
  }  
  & .reverse-direction {
      padding-bottom: 10px;
  }  

  @media screen and ${device('xs')} {
    font-size: 14px;
  }

  @media screen and ${device('xs', 'min')} and ${device('desktop')} {
    font-size: 15px;
  }

  @media screen and (max-width: 768px) {
    ${({ position }) => position === "left" ?
      `width: 100%;
            margin-right: 0;
            min-width: unset;` :
      position === "right" ?
        `width: 100%;
                     min-width: unset;` : ''};
  }

  & > .position-relative {
    position: relative;

    & > label:not(.without-padding) {
      min-width: 150px;
    }

    & span {
      top: 10px;
    }
  }


  @media screen and ${device('xs')} {
    .input-group {
      margin-bottom: 0;

      input {
        font-size: 14px;
        height: 32px;
        padding: 3px 5px;
      }
    }
  }

  & .flex-inline {

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

    &.between {
      justify-content: space-between;
    }

    .radio__input, .checkbox__input {
      margin-right: 10px;
      position: absolute;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    .custom-radio, .custom-checkbox {
      width: 20px;
      height: 20px;
      background-image: url(${({ theme }) => theme.backgroundCheckboxInput});
      background-position: -160px 0;
    }

    .custom-checkbox {
      background-position: -5px 0;
    }

    .radio__input {
      &:checked + .custom-radio {
        background-position: -222px 0;
      }
    }

    .checkbox__input {
      &:checked + .custom-checkbox {
        background-position: -67px 0;
      }
    }

    label {
      z-index: 2;
    }

    label:not(.without-padding) {
      margin-left: -1.5em;
      padding-left: 1.7em;
    }

    .limits-label {
      min-width: 0;
      
      @media screen and ${device('xs')} {
        min-width: 152px;
      }
    }
  }

  & > div {
    padding: 6px 0;

    @media screen and ${device('xs')} {
      padding: 0;
    }
  }

  select {
    width: 98px;
    display: inline-block;
    margin: 2px 0 5px 0;
    text-align: left;
    padding: 5px 8px 7px;
    font-size: 16px;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    background-color: ${({ theme }) => theme.inputBgColor};
    border: 1px ${({ theme }) => theme.inputBorderColor} solid;
    clear: both;
    cursor: pointer;
    color: ${({ theme }) => theme.inputColor};
    border-radius: 3px;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    outline: 0;

    @media screen and ${device('xs')} {
      font-size: 14px;
    }

    &:focus {
      border-bottom: 1px solid #058bd8;
    }

    option {
      cursor: pointer;

      &:checked, &:hover {
        background-color: ${({ theme }) => theme.leftSidebar.blockBorderColor};
      }
    }
  }
`;

export const StyledTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px;
`;
