import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  StyledRadioBox,
  StyledTitle,
  StyledSlider,
  StyledSliderBackBar,
  StyledSliderSelectedBar,
  StyledSliderPointer,
  StyledSliderClickableDummy,
} from "./styledAutoBets";
import { useTranslation } from "react-i18next";

const AutoBetsAcceleration = React.memo(({ autoMode, setAutoMode }) => {
  const { t } = useTranslation("games");
  const [dragging, setDragging] = useState(false);
  const sliderRef = useRef(null);

  const handleSliderClick = useCallback((event) => {
    const rect = event.target.getBoundingClientRect();
    const localX = event.clientX - rect.x;
    const value = Math.round((localX / rect.width) * 3);
    setAutoMode((prevState) => ({
      ...prevState,
      acceleration: value,
    }));
  }, [setAutoMode]);

  const handleMove = useCallback((clientX) => {
    if (dragging && sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const localX = clientX - rect.x;
      let value = Math.round((localX / rect.width) * 3);
      if (value < 0) value = 0;
      if (value > 3) value = 3;
      setAutoMode((prevState) => ({
        ...prevState,
        acceleration: value,
      }));
    }
  }, [dragging, setAutoMode]);

  const handleMouseMove = useCallback((event) => {
    handleMove(event.clientX);
  }, [handleMove]);

  const handleTouchMove = useCallback((event) => {
    handleMove(event.touches[0].clientX);
  }, [handleMove]);

  const handleStart = useCallback(() => {
    setDragging(true);
  }, []);

  const handleEnd = useCallback(() => {
    setDragging(false);
  }, []);

  useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleEnd);
      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleEnd);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleEnd);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleEnd);
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleEnd);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleEnd);
    };
  }, [dragging, handleMouseMove, handleTouchMove, handleEnd]);

  return (
      <StyledRadioBox>
        <StyledTitle>{t("acceleration")}</StyledTitle>
        <div className="flex-inline">
          <StyledSlider
              ref={sliderRef}
              onMouseDown={handleStart}
              onTouchStart={handleStart}
          >
            <StyledSliderBackBar>
              <StyledSliderSelectedBar value={autoMode.acceleration} />
              <StyledSliderPointer value={autoMode.acceleration} />
              <StyledSliderClickableDummy
                  onClick={handleSliderClick}
                  onMouseDown={handleStart}
                  onTouchStart={handleStart}
              />
            </StyledSliderBackBar>
          </StyledSlider>
        </div>
      </StyledRadioBox>
  );
});

export default AutoBetsAcceleration;