import styled from "styled-components";

export const StyledPageTemplateWrapper = styled.div`
  padding: 35px 0 35px;
  min-height: 470px;
  background: url("${({ game, theme }) => game ? (game === 'roulette' ? theme.bgIdRoulette : game === 'balls' ? theme.bgIdBalls : theme.bgIdDice) : theme.bgTemplatePage}") no-repeat 50% 0;

  .page-title {
    color: ${({ theme }) => theme.subTitleColor};
  }
`;