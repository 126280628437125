import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import KeepAlive from 'react-activation';
import HelpContainer from "../../components/home/helpSection/HelpContainer";

import { StyledContainer } from "../../components/styles/styledContainer";
import RouletteContainer from "../../components/games/roulette/RouletteContainer";

const RoulettePage = () => {
  const { t } = useTranslation("games");

  return (
    <StyledContainer>
      <Helmet>
        <title>{t("meta.roulette.title")}</title>
        <meta
          name="description"
          content={t("meta.roulette.description")}
        />
        <link
          rel="canonical"
          href={"https://" + window.location.hostname + "/roulette"}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/roulette"}
          hrefLang="en-US"
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ru/roulette"}
          hrefLang="ru-RU"
        />
      </Helmet>
      <HelpContainer />
      <KeepAlive>
        <RouletteContainer />
      </KeepAlive>
    </StyledContainer>
  );
};

export default RoulettePage;
