import styled, { css } from "styled-components";
import { StyledContentWrapper } from "../../styles/styledContainer";
import { device, hideOnMedia } from "../../styles/responsive/responsiveUtils";


export const StyledHeaderWrapper = styled.header`
  height: 75px;
  color: #fff;
  display: flex;
  background-color: ${({ theme }) => theme.headerBg};
  @media screen and ${device('desktop')} {
    position: fixed;
    z-index: 30;
    left: 0;
    right: 0;
    height: 64px;
  }
`;

export const StyledHeaderContainer = styled(StyledContentWrapper)`
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all .3s ease;
  @media screen and ${device('desktop')} {
    padding: 0 20px;
  }
`;

export const StyledMobileNav = styled.div`
  display: none;
  max-height: 0;
  height: calc(100% - 75px);
  position: absolute;
  top: 75px;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.paymentMethodMobileBgColor};
  padding: 0 20px;
  overflow-y: auto;
  z-index: 11;
  transition: all .2s;
  ${({ isOpen }) => isOpen && `
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    max-height: 100%;
    position: fixed;
  `};
  ${({ isOpenCurrency }) => isOpenCurrency && `
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    max-height: 100%;
    position: fixed;
    justify-content: start;
  `};
  @media screen and ${device('desktop')} {
    
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    ${({ fullscreen }) => fullscreen ?
      css`
        top: 0;
        height: 100%;
        padding: 0;
      ` :
      css`
        top: 64px;
        height: calc(100% - 64px);
      `
    }
    
    &.currency-nav {
      

    }

    nav {
      margin-bottom: 10px;

      @media screen and (max-width: 425px) {
        ul {
          flex-direction: column;
        }

        li {
          padding: 0;
          justify-content: center;

          &:before {
            width: 100%;
            height: 2px;
            top: 0;
          }
        }

        .menu-item a {
          padding: 10px 0;
        }
      }
    }

    nav, div:not(.chat-header,
                 .chat-header-actions, 
                 .messages-container,
                 .chat-messages,
                 .message-body,
                 .message-body div,
                 .chat-bottom-actions,
                 .chat-bottom-actions div,
                 .chat-emoji-block,
                 .chat-rooms,
                 .chat-info-block,
                 .chat-info-block div,
                 .chat-bottom-info,
                 .chat-wrapper,
                 .chat-header,
                 .payment-method-item,
                 .coin-search,
                 .coin-search__clean,
                 .statistic-flex,
                 .light div,
                 .maximum div) {
      display: block;
    }

    div:not(.chat-header, 
            .chat-header-actions, 
            .messages-container,
            .chat-messages,
            .message-body,
            .message-body div,
            .chat-bottom-actions,
            .chat-bottom-actions div,
            .chat-emoji-block,
            .chat-rooms,
            .chat-info-block,
            .chat-info-block div,
            .chat-bottom-info,
            .chat-wrapper,
            .chat-header,
            .game-name,
            .coin-search__clean,
            .statistic-one-block,
            .alert) {
      width: 100%;
      height: unset;
      position: unset;
      top: unset;
      left: unset;

      &.coin-search {
        position: relative;
      }
    }

    div ul {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3px;
    }

    div ul li {
      width: 63px;
      height: 62px;
      margin: 0;
      background-color: ${({ theme }) => theme.mobileNav.item.backgroundColor};
      color: ${({ theme }) => theme.mobileNav.item.color};
      font-size: 12px;
      padding: 9px 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      
      img {
        width: 26px;
        margin: 0;
      }
      
      &:hover{
        background-color: ${({ theme }) => theme.mobileNav.itemHover.backgroundColor};
        color: ${({ theme }) => theme.mobileNav.itemHover.color};
      }
    }

    .account-setting, .list-language {
      width: 100%;
      border-radius: 4px;
      flex-direction: column;

      .list-language img {
        width: 26px;
        height: 20px;
        border-radius: 3px;
      }

      li {
        display: flex;
        align-items: center;
        width: 100%;
        height: unset;
        padding: 8px 15px;
        color: ${({ theme }) => theme.mobileNav.item.color};
        background: ${({ theme }) => theme.mobileNav.item.backgroundColor};
        border-radius: 4px;
        margin-bottom: 8px;
        font-size: 16px;

        &:not(:last-child) {
          margin-right: 0;
        }

        button, a{
          width: 100%;
          text-align: start;

          &:hover {
            color: ${({ theme }) => theme.mobileNav.itemHover.color};
          }
        }

        &:hover, a:hover {
          &:hover {
            color: ${({ theme }) => theme.mobileNav.itemHover.color};
            background: ${({ theme }) => theme.mobileNav.itemHover.backgroundColor};
          }
        }
      }
    }

    .list-language {
      img {
        width: 26px;
        height: 20px;
        border-radius: 3px;
        margin-right: 8px;
      }

      a {
        display: flex;
        align-items: center;
        width: 100%;
      }
    }

    .payment-method-item:first-child {
      margin-top: 10px;
    }

    .payment-method-item:last-child {
      margin-bottom: 10px;
    }
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  &:last-child {
    padding-bottom: 15px;
  }

  h3 {
    color: ${({ theme }) => theme.mainTextColor};;
    padding: 20px 0 10px;
    font-size: 15px;
    font-weight: normal;
  }

  & > div {
    padding: 0;
  }

  .list-language {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0;
    a {
      opacity: 0.6;
    }
    .active {
      opacity: 1;
    }
    &:hover a{
      opacity: 1 !important;
    }
  }

  .my-profile {
    margin: 0 5% 2px;
    font-size: 14px;
    border-radius: 4px;
    background: #171717;

    a {
      padding: 9px 15px;
      width: 100%;
    }
  }

  .link-profile, .link-transaction {
    width: 100%;
    text-align: left;
  }
  
  &.account-nav {
    ${hideOnMedia('xs', 'min')}
  }
`;

export const StyledAdminHeaderContainer = styled(StyledContentWrapper)`
  max-width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all .3s ease;
  //@media screen and (max-width: 992px) {
  //  padding: 0 15px;
  //}
`;