import styled from "styled-components";
import loadImage from "../../../assets/images/load-avatar.png";
import close from "../../../assets/images/close-popup-avatar.png";

export const StyledWrapperAvatarLoad = styled.div`
  position: relative;
  
  .clean-input {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 26px;
    height: 26px;
    background: url(${close}) no-repeat center;
    z-index: 2;
    cursor: pointer;
  }
`;

export const StyledLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 300px;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px dashed ${({theme}) => theme.borderUploadAvatar};
  background-color: ${({theme}) => theme.lightBackground};
  color: #929292;
  cursor: pointer;
  text-align: center;
  z-index: 3;
  transition: all 0.15s ease-in-out;
  
  input {
    display: none;
  }
  
  .file-photo {
    position: absolute;
    top: 25px;
    left: 50%;
    width: 250px;
    height: 250px;
    transform: translateX(-50%);
    display: none;
    border-radius: 100%;
    overflow: hidden;
    
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: center;
      border-radius: 100%;
    }
  }
  
  &.active {
    z-index: 1;

    .file-photo {
      display: block;
    }
    
    .label-file__text {
      display: none;
    }
    
    &:before {
      display: none;
    }
  }
  
  &:before {
    content: "";
    display: block;
    width: 180px;
    height: 119px;
    margin-bottom: 22px;
    background: url(${loadImage}) no-repeat center;
    opacity: ${({theme}) => theme.opacityUploadAvatar};
    transition: all 0.15s ease-in-out;
  }
`;