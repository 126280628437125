import React from "react";
import { StyledHelpWrapper } from "./styledHelp";

const HelpContainer = () => {
  return (
    <StyledHelpWrapper>
      <span>!</span>
      <p>The legendary luckygames.io is back!</p>
    </StyledHelpWrapper>
  )
}

export default HelpContainer