import styled from "styled-components";

export const StyledQrCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 20px 0;
  margin: 20px 0;
  background-color: ${({ theme }) => theme.twoFa.codeBg};
  border-radius: 3px;
`;

export const StyledModalLoader = styled.div`
  min-height: ${({ minHeight }) => minHeight ?? '400px'};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 460px;
}
`;

export const StyledDepositBonus = styled.div`
  display: flex;
  align-items: center;  
  justify-content: space-between;  
  margin-top: 15px;
  p {
    font-size: 14px;
    span {
        color: ${({ theme }) => theme.subTitleColor};
        font-weight: 500;
    }    
  }  
`;

export const StyledHelpElement = styled.div`
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.neutralColor};
    border-radius: 100%;
    font-size: 16px;
    color: ${({ theme }) => theme.tokens.notificationAmountToken.iconColor};
    cursor: pointer;
    margin-left: 7px;
    transition: background-color 0.2s ease;
    
    .help-el__text {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 250px;
        padding: 10px;
        background: ${({ theme }) => theme.neutralColor};
        font-size: 12px;
        transition: background-color 0.2s ease;
    }

    &:hover {
        background: ${({ theme }) => theme.neutralColorHover};
        .help-el__text {
            display: block;
            background: ${({ theme }) => theme.neutralColorHover};
        }
    }
`
