import styled from "styled-components";
import { device } from "../../styles/responsive/responsiveUtils";
import {StyledLink} from "../../../pages/faq/styledFaqPage";

const inputStyleTemplate = ( theme ) => {
  return `
    width: 100%;
    padding: 4px 15px 7px;
    font-size: 20px;
    font-family: inherit;
    color: ${theme.inputColor};
    background-color: ${theme.inputBgColor};
    border: 1px solid ${theme.inputBorderColor};
    box-shadow: ${theme.inputBoxShadow};
    border-radius: 3px;
    -webkit-appearance: none;

    &:focus {
      border-bottom: 1px solid ${theme.defaultChangeColor};
    }
    /* &:focus, &:active {
      border: 1px solid rgba(25, 54, 54, 0.5);
      box-shadow: 0px 10px 14px rgba(25, 54, 54, 0.4);
    }*/

    /*&::placeholder {
      opacity: 0.55;
    }*/

    /*&:-webkit-autofill {
      -webkit-box-shadow: inset 0 0 0 50px black;
      -webkit-text-fill-color: blue;
    }*/

    &:read-only {
      background-color: ${theme.inputBorderColorDisabled};
      color: ${theme.readOnlyInput};
    }

    /*&:read-only:focus{
      box-shadow: none;
      background-color: transparent;
    }*/
  `;
};

export const StyledInputGroup = styled.div`
  ${({autoInput}) => !autoInput && 'margin-bottom: 15px'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({type}) => type === 'hidden' && `
    height: 0;
    margin: 0 !important;
    visibility: hidden;
    opacity: 0;
  `};

  &.form-text {
    input {
      font-size: 14px;
      padding: 8px 15px 11px;
    }
  }

  &.text {
    input {
      font-size: 14px;
      padding: 7px 8px;
      ${({ padding }) => `padding: ${padding}`};
    }
  }

  input {
    ${({ padding }) => padding && `padding: ${padding}`};
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 7px;
  }
`;

export const StyledFlexAlign = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ position }) => position ? position : "space-between"};
  ${({ mb }) => mb && `margin-bottom: ${mb}px;`};
  ${({ mt }) => mt && `margin-top: ${mt}px;`};
`;

export const StyledHintText = styled.p`
  font-size: 14px;
`;

export const StyledLabel = styled.label`
  padding-bottom: 7px;
  color: ${({theme}) => theme.labelColor};
  font-size: 16px;
  text-align: left;
  user-select: none;
  transition: all 0.3s ease;

  @media screen and ${device('xs', 'min')} and ${device('desktop')} {
    font-size: 15px;
  }

  @media screen and ${device('xs')} {
    font-size: 12px;
  }
`;

export const StyledTextarea = styled.textarea`
  height: 125px;
  font-family: inherit;
  resize: none;
  border: none;
  outline: none;
  ${({ theme }) => inputStyleTemplate(theme)}
`

export const StyledInput = styled.input`
  ${({ theme }) => inputStyleTemplate(theme)};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
  ${({ width }) => width && `width: ${width}`};
  ${({ height }) => height && `height: ${height}`};
  ${({ paddingInput }) => paddingInput && `padding: ${paddingInput}`};
  ${({ marginInput }) => marginInput && `margin: ${marginInput}`};
  ${({ fontSizeInput }) => fontSizeInput && `font-size: ${fontSizeInput}`};
  font-size: ${({ fontSizeInput }) => fontSizeInput ?? '15px'};

  @media screen and ${device('xs', 'min')} {
    font-size: ${({ fontSizeInput }) => fontSizeInput ?? '17px'};
  }

  @media screen and ${device('desktop', 'min')} {
    font-size: ${({ fontSizeInput }) => fontSizeInput ?? '20px'};
  }

  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'],
  &[type="number"]:hover,
  &[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
`;

export const StyledDelayInput = styled.div`
  input {
    ${({ theme }) => inputStyleTemplate(theme)}
  }
`;

export const StyledInputWrapper = styled.div`
  position: relative;
  ${({error}) =>
  error && `input, textarea {
      color: #D25C65;
      border: 1px solid #D25C65;
      box-shadow: 0 1px 4px rgb(219 36 36 / 50%);
      &:focus, &:active {
        border: 1px solid #D25C65;
        box-shadow: 0 5px 10px rgb(219 36 36 / 40%)
      }
    }`
  };

  &.active::before {
    ${({autoModeIsStart}) => autoModeIsStart ?
            'transition: all .5s linear;' +
            'transform: translate(-50%, -18px);' +
            'opacity: 1;' :
            'animation: .5s linear alternate sun-rise'};
  }

  &::before {
    ${({balance}) => (balance?.win || balance?.lose) &&
            `content: "${balance?.win || balance?.lose}";
             opacity: 0;
             visibility: hidden;
             position: absolute;
             left: 50%;
             transform: translateX(-50%);
             font-size: 20px;
             color: ${balance?.win ? "#2eac5b" : "#ab2e40"};
             `};
  }

  @keyframes sun-rise {
    from {
      transform: translate(-50%, -10px);
      opacity: 1;
      visibility: visible;
    }
    to {
      transform: translate(-50%, -18px);
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export const StyledPasswordEye = styled.span`
  margin: 0;
  padding: 0;
  color: ${({theme}) => theme.labelColor};
  font-size: 20px;
  position: absolute;
  bottom: 11px;
  right: 20px;
  cursor: pointer;
  opacity: 0.8;
  
  &:hover {
    opacity: 1;
  }
  
  @media screen and ${device('xs')} {
    font-size: 15px;
    bottom: 9px;
    right: 15px;
  }

  @media screen and ${device('xs', 'min')} and ${device('desktop')} {
    font-size: 17px;
    bottom: 10px;
    right: 17px;
  }
`;

export const StyledError = styled.div`
  padding-top: 3px;
  color: #D25C65;
  font-size: 12px;
  text-align: left;
`;

export const StyledSkeletonInput = styled.div`
  width: 100%;
  height: 49px;
  padding: 10px 12px;
  background-color: transparent;
  border: 1px solid #9DA6B6;
  border-radius: 5px;
  & > div {
    margin: 0;
  }
`;

export const StyledUnconfirmedCountLink = styled(StyledLink)`
  color: ${({ theme }) => theme.historyLink};
  border-color: ${({ theme }) => theme.transactionHist.infoBd};
  
  &:hover {
    color: ${({ theme }) => theme.historyLinkHover};
    border-color: ${({ theme }) => theme.historyLinkHover};
  }
`
