import styled from "styled-components";
import { device } from "./responsive/responsiveUtils";

export const StyledContainer = styled.div`
  max-width: 1300px;
  width: 100%;
  min-height: calc(100vh - 75px - 108px);
  margin: 0 auto;

  .custom-hint {
    position: relative;
    width: 0;
    transform: translate(20px, -70px);
    z-index: 100;
  }

  @media screen and ${device('desktop')} {
    padding-top: 64px;
  }
`;

export const StyledContentWrapper = styled.div`
  max-width: ${({ width }) => width ? width + "px" : "1000px"};
  display: ${({ isFlex }) => isFlex ? "flex" : "block"};
  flex-direction: ${({ column }) => column ? "column" : "row"};
  align-items: ${({ position }) => position ? position : "unset"};
  width: 100%;
  margin: 2px auto;
  position: relative;
  font-size: 15px;
  @media screen and ${device('desktop')} {
    max-width: unset;
    padding: 20px;
  }
  @media screen and (max-width: 768px) {
    .account-wrapper__top {
      flex-direction: column;
      align-items: center;

      & > div {
        width: 100%;
      }
    }

    .account-wrapper__other {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`;

export const StyledCloseImg = styled.a.attrs(({ children: "✕" }))`
  font-size: 27px;
  color: ${({ theme }) => theme.closeIconColor};
  position: absolute;
  top: ${({ top }) => top ? top : '5px'};
  right: ${({ right }) => right ? right : 0};
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.text};;
  }
  
  @media screen and ${device('desktop')} {
    right: 20px;
    top: ${({ top }) => top ? top : '21px'};
  }
`;

export const StyledInformationContainer = styled.div`
  max-width: ${({ width }) => width + "px" || "none"};
  width: 100%;

  .information {
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    border-bottom: 1px solid #F1F1F1;

    &__value {
      width: 100%;
      color: #058bd8;
      overflow-wrap: break-word;
    }
  }

  @media screen and (max-width: 768px) {
    max-width: none;
    .information {
      padding: 12px 0;
      grid-template-columns: 100%;

      &__key {
        font-weight: 600;
      }
    }
  }
`;

export const StyledAdminWrapper = styled.div`
  display: flex;
`;

export const StyledAdminContainer = styled.div`
  padding: 20px 25px 30px;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
`;
